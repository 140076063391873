import { get, post, postparam, upload } from '@/utils/http';
export default {
    geeTestInit: () => get('/geevali/init/'),
    geeTestCheck: (data) => postparam('/geevali/check/', data),
    sendEmailValiCode: (data) => postparam('/sms/emailVerifyCode', data),
    sendPhoneValiCode: (data) => post('/sms/sendVrifySms/', data),
    checkCode: (data) => postparam('/register/checkCode', data),
    getImageGroup: () => get('/images/getImageGroup'),
    getImageGroupList: (data) => get('/images/getImageList', data),
    uploadNetworkImage: (data) => post('/images/uploadNetworkImage', data),
    uploadLocalImage: (data) => upload('/images/uploadLocalImage', data),
    uploadMoreImage: (data) => upload('/images/upload_more_images', data),
    getMenuList: () => get('/role/menu/user')
};
