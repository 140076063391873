import * as intl from 'react-intl-universal';
import message from "antd/lib/message";
import Http from '@/common/http';
import errors, { whiteList } from './errors';
import { list } from './retry';
const errorMsg = (code, value) => (errors[code]
    ? intl.get(errors[code]).d(errors[code])
    : value || intl.get('Util.OperateError'));
const codeHandler = (code, data) => {
    switch (code) {
        default:
            return message.error(errorMsg(code, data));
    }
};
const buildUrlWithParams = (baseUrl, params) => {
    const queryParams = new URLSearchParams(params).toString();
    return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
};
const http = Http({
    onError: ({ code, data }, url) => {
        if (code === 1006) {
            codeHandler(code, data);
            setTimeout(() => window.location.reload(), 500);
            return;
        }
        if (!whiteList.includes(url)) {
            codeHandler(code, data);
            if (code === 2000 || code === 2800 || code === 2801 || code === 2802 || code === 2803) {
                setTimeout(() => window.location.reload(), 3000);
            }
        }
    },
    retry: {
        list,
        callback: (delay) => {
            message.error(intl.get('Hint.RetryRequest', { delay }).d(`Request Failed，${delay} 秒后自动重试`));
        }
    }
});
const get = http('get');
const post = http('post');
const postparam = http('postparam');
const put = http('put');
const upload = http('upload');
const del = http('delete');
const postquery = http('postquery');
const getpath = http('getPath');
export { get, post, postparam, put, upload, errorMsg, del, postquery, getpath, buildUrlWithParams };
