var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as moment from 'moment';
import DatePicker from "antd/lib/date-picker";
const { RangePicker } = DatePicker;
export default class Picker extends React.Component {
    constructor() {
        super(...arguments);
        this.formatValue = (dateStrings) => {
            if (Array.isArray(dateStrings) && dateStrings.length > 0) {
                return [moment(dateStrings[0]), moment(dateStrings[1])];
            }
            return null;
        };
        this.disabledDate = (current) => current && current.valueOf() > Date.now();
        this.disabledDateWithToday = (current) => {
            const currDate = current.format('YYYY-MM-DD');
            const today = moment(new Date()).format('YYYY-MM-DD');
            return current && (current.valueOf() > Date.now() || currDate === today);
        };
    }
    get ranges() {
        var _a;
        let { lang, disabledRanges = [] } = this.props;
        lang = (_a = lang !== null && lang !== void 0 ? lang : window.localStorage.getItem('_uLang')) !== null && _a !== void 0 ? _a : 'zh-CN';
        const defaultRanges = [
            {
                key: 'd',
                text: Picker.locale[lang].Today,
                value: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
            },
            {
                key: 'd-1',
                text: Picker.locale[lang].Yesterday,
                value: [moment('00:00:00', 'HH:mm:ss').subtract(1, 'd'), moment('23:59:59', 'HH:mm:ss').subtract(1, 'd')]
            },
            {
                key: '7d',
                text: Picker.locale[lang].LastSevenDay,
                value: [moment('00:00:00', 'HH:mm:ss').subtract(6, 'd'), moment('23:59:59', 'HH:mm:ss')]
            },
            {
                key: '30d',
                text: Picker.locale[lang].LastThirtyDay,
                value: [moment('00:00:00', 'HH:mm:ss').subtract(29, 'd'), moment('23:59:59', 'HH:mm:ss')]
            },
            {
                key: '180d',
                text: Picker.locale[lang].HalfYear,
                value: [moment('00:00:00', 'HH:mm:ss').subtract(179, 'd'), moment('23:59:59', 'HH:mm:ss')]
            },
            {
                key: 'm-1',
                text: Picker.locale[lang].LastMonth,
                value: [moment('00:00:00', 'HH:mm:ss').subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
            },
            {
                key: 'm',
                text: Picker.locale[lang].ThisMonth,
                value: [moment('00:00:00', 'HH:mm:ss').startOf('month'), moment('23:59:59', 'HH:mm:ss').endOf('month')]
            }
        ];
        return (disabledRanges.length ? defaultRanges.filter(({ key }) => !disabledRanges.includes(key)) : defaultRanges).reduce((s, { text, value }) => (Object.assign(Object.assign({}, s), { [text]: value })), {});
    }
    render() {
        const _a = this.props, { className = '', defaultDate = [], disabled = false, onDateChange, showTime, disabledDate } = _a, rest = __rest(_a, ["className", "defaultDate", "disabled", "onDateChange", "showTime", "disabledDate"]);
        return (React.createElement(RangePicker, Object.assign({}, rest, { className: className, showTime: showTime &&
                {
                    format: 'HH:mm:ss',
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                }, disabledDate: disabledDate &&
                (typeof disabledDate === 'string'
                    ? disabledDate === 'withToday'
                        ? this.disabledDateWithToday
                        : this.disabledDate
                    : disabledDate), format: showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD', ranges: this.ranges, value: this.formatValue(defaultDate), onChange: (_, dateStrings) => onDateChange(dateStrings), disabled: disabled })));
    }
}
Picker.locale = {
    'zh-CN': {
        Yesterday: '昨天',
        Today: '今天',
        LastSevenDay: '最近7天',
        LastThirtyDay: '最近30天',
        HalfYear: '最近180天',
        LastMonth: '上月',
        ThisMonth: '当月'
    },
    'zh-TW': {
        Yesterday: '昨天',
        Today: '今天',
        LastSevenDay: '最近7天',
        LastThirtyDay: '最近30天',
        HalfYear: '最近180天',
        ThisMonth: '當月',
        LastMonth: '上月'
    },
    'en-US': {
        Yesterday: 'Yesterday',
        Today: 'Today',
        LastSevenDay: 'Last 7 days',
        LastThirtyDay: 'Last 30 days',
        HalfYear: 'Last 180 days',
        LastMonth: 'Last month',
        ThisMonth: 'This month'
    }
};
