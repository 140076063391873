var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { default as RcTreeSelect } from "antd/lib/tree-select";
import StaticView from './StaticView';
export default class TreeSelect extends React.Component {
    constructor() {
        var _a;
        super(...arguments);
        this.state = {
            treeExpandedKeys: ((_a = this.props) === null || _a === void 0 ? void 0 : _a.treeExpandedKeys) || [],
            loadedDataMap: {}
        };
        this.loadedDataMapHandler = (treeData) => {
            const getChildren = (nodeTree) => nodeTree.reduce((prev, { value, children }) => (Object.assign(Object.assign({}, prev), (children ? Object.assign({ [value]: true }, getChildren(children)) : {}))), {});
            this.setState({
                loadedDataMap: getChildren(treeData)
            });
        };
        this.adaptHandler = (treeData = [], value = []) => {
            const getChildrenDesc = (nodeTree) => nodeTree.reduce((prev, { key, full_label, full_title, label, title, children, value }) => (Object.assign(Object.assign({}, prev), (children ? getChildrenDesc(children) : {
                [key || value]: full_label || full_title || label || title
            }))), {});
            const dataMap = getChildrenDesc(treeData);
            return value.filter((key) => !!dataMap[key]).map((key) => dataMap[key]).join('　');
        };
        this.onLoadDataHandler = (node) => new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
            var _b;
            if (this.state.loadedDataMap[node.key || node.value]) {
                resolve(true);
            }
            else {
                resolve(((_b = this.props) === null || _b === void 0 ? void 0 : _b.loadData) && this.props.loadData(node));
            }
        }));
        this.onExpandHandler = (keys) => {
            this.setState({
                treeExpandedKeys: keys
            }, () => { var _a; return ((_a = this.props) === null || _a === void 0 ? void 0 : _a.onTreeExpand) && this.props.onTreeExpand(keys); });
        };
        this.onDropdownChangeHandler = (isOpen) => isOpen && this.onExpandHandler([]);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.treeData) !== JSON.stringify(nextProps.treeData)) {
            this.loadedDataMapHandler(nextProps.treeData);
        }
    }
    componentDidMount() {
        this.loadedDataMapHandler(this.props.treeData);
    }
    render() {
        const { staticView, disabled, readOnly, treeData, value, strategy = 'all' } = this.props;
        const { treeExpandedKeys } = this.state;
        return (staticView && disabled || readOnly)
            ? React.createElement(StaticView, { adaptFn: () => this.adaptHandler(treeData, value), disabled: disabled })
            : React.createElement(RcTreeSelect, Object.assign({}, this.props, { showCheckedStrategy: TreeSelect.strategy[strategy], loadData: this.onLoadDataHandler, onTreeExpand: (keys) => this.onExpandHandler(keys), treeExpandedKeys: treeExpandedKeys, onDropdownVisibleChange: this.onDropdownChangeHandler }));
    }
}
TreeSelect.fieldName = 'treeSelect';
TreeSelect.type = 'treeSelect';
TreeSelect.strategy = {
    'all': RcTreeSelect.SHOW_ALL,
    'parent': RcTreeSelect.SHOW_PARENT,
    'child': RcTreeSelect.SHOW_CHILD
};
