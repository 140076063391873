var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const config = {
    delay: {
        1: 1000,
        2: 3000,
        3: 10000
    }
};
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const retry = (request, callback, times = 1) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        return yield request();
    }
    catch (err) {
        if (times <= 3) {
            callback && callback(config.delay[times] / 1000);
            yield delay(config.delay[times]);
            return yield retry(request, callback, times + 1);
        }
        return Promise.reject(err);
    }
});
export default (options) => (request, url) => __awaiter(void 0, void 0, void 0, function* () {
    return (options.list.includes(url)
        ? yield retry(request, options.callback)
        : yield request());
});
