class Cache {
    constructor() {
        this.store = {};
        this.get = (key) => this.store[key];
        this.set = (key, value) => {
            this.store[key] = value;
            return this;
        };
        this.clear = () => {
            this.store = {};
            return this;
        };
    }
}
export default new Cache();
