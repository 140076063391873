import * as React from 'react';
import * as intl from 'react-intl-universal';
import message from "antd/lib/message";
export default (options = {}) => (WrappedComponent) => class Clipboard extends React.Component {
    constructor() {
        super(...arguments);
        this.locale = options.locale || {
            copySucceeded: intl.get('Hint.CopySucceeded'),
            copyFailed: intl.get('Hint.CopyFailed')
        };
        this.isIOS = navigator.userAgent.match(/iphone|ipod|ipad|ios/i);
        this.copy = (text) => {
            const node = document.createElement('textarea');
            document.body.appendChild(node);
            node.value = text;
            node.textContent = text;
            if (this.isIOS) {
                const selection = getSelection();
                const range = document.createRange();
                range.selectNode(node);
                selection.removeAllRanges();
                selection.addRange(range);
                node.setSelectionRange(0, 999999);
            }
            else {
                node.select();
            }
            document.execCommand('copy')
                ? message.success(this.locale.copySucceeded)
                : message.error(this.locale.copyFailed);
            document.body.removeChild(node);
        };
    }
    render() {
        const clipboard = {
            copy: this.copy
        };
        return (React.createElement(WrappedComponent, Object.assign({}, this.props, { clipboard: clipboard })));
    }
};
