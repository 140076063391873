export class Config {
    constructor() {
        this.cdn_file = (filePath) => `${this.cdn_host}${filePath}`;
        this.js_file = (filePath) => `/public/js${filePath}`;
        const url = window.cdn_host;
        if (url)
            this.cdn_host = `${url}${url.charAt(url.length - 1) !== '/' ? '/' : ''}`;
    }
}
export default new Config();
