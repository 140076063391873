import * as React from 'react';
import * as ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import 'babel-polyfill';
import * as Sentry from "@sentry/react";
import config from './config';
import store from './store';
import PayoutOrderProvider from './store/context/payoutOrder';
import './style/index.less';
import Root from './pages/Root';
window.config = config;
if (window.location.hostname !== 'localhost') {
    Sentry.init({
        dsn: `https://${SENTRY_KEY}@${window.location.host}/sentry/${SENTRY_PROJ_ID}`,
        integrations: [
            Sentry.browserTracingIntegration()
        ],
        environment: process.env.NODE_ENV || 'development',
        tracesSampleRate: 1.0
    });
}
ReactDom.render(React.createElement(Provider, { store: store },
    React.createElement(PayoutOrderProvider, null,
        React.createElement(Root, null))), document.getElementById('app'));
