var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import * as intl from 'react-intl-universal';
import Upload from "antd/lib/upload";
import message from "antd/lib/message";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import FilePreview from '@/common/components/FilePreview';
import Icon from '@/components/Icon';
import { common } from '@/api';
import { nanoid } from 'nanoid';
const UploadLocal = (props) => {
    const { accept = 'image/*', maxCount = 1, value, onChange } = props;
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState(value || []);
    useEffect(() => {
        var _a;
        if (value && Array.isArray(value)) {
            if ((_a = value[0]) === null || _a === void 0 ? void 0 : _a.id) {
                setFileList(value);
            }
            else {
                setFileList(value.map((item) => (Object.assign(Object.assign({}, item), { id: nanoid() }))));
            }
        }
    }, [value]);
    const customRequest = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const { file } = data;
        const imageData = {};
        Array.isArray(file)
            ? file.forEach((item) => {
                imageData['file'] = item;
            })
            : (imageData['file'] = file);
        const result = yield common.uploadMoreImage(imageData);
        const { ret_code, ret_msg } = result;
        if (ret_code !== 1) {
            message.error(intl.get('Global.NotificationWarningId', { field: `${window._traceid}` }) + intl.get('Global.NotificationWarningDescription'));
            return;
        }
        const fileNameReg = /(?:[^']*\/)?([^\/\.]*)/;
        let matchArr = [];
        const list = ret_msg.map((item) => {
            matchArr = item.match(fileNameReg);
            return {
                id: matchArr[1],
                name: matchArr[1],
                path: item,
                url: item
            };
        });
        setFileList([...fileList, ...list]);
        onChange && onChange([...fileList, ...list]);
        setLoading(false);
    });
    const deleteFile = (index) => {
        const files = [...fileList];
        files.splice(index, 1);
        setFileList(files);
        onChange && onChange(files);
    };
    const renderUploadButton = () => {
        if (fileList.length >= maxCount) {
            return null;
        }
        return React.createElement(PlusOutlined, { style: { fontSize: 16 } });
    };
    return (React.createElement("div", { className: "field-upload" },
        React.createElement("div", { className: "upload-list" },
            fileList.map((item, index) => (React.createElement("div", { className: "upload-list-item", key: item.id },
                React.createElement(FilePreview, { src: item.url }),
                React.createElement(Icon, { icon: "delete", className: "group-image-delete", onClick: () => deleteFile(index) })))),
            React.createElement("div", { className: "upload-box" },
                React.createElement(Upload, Object.assign({}, props, { listType: "picture-card", withCredentials: true, fileList: fileList, accept: accept, maxCount: maxCount, showUploadList: false, customRequest: customRequest }), loading ? React.createElement(LoadingOutlined, null) : renderUploadButton())))));
};
export default UploadLocal;
