import { get, post, postparam } from '@/utils/http';
export default {
    checkStatus: () => get('/users/getUser'),
    checkLogin: () => get('/common/checkLogin/'),
    login: (data) => postparam('/users/ajaxlogin/', data),
    logout: () => get('/users/reLogout'),
    checkAccountExist: (data) => post('/users/checkAccountExt/', data),
    checkPhone: (data) => post('/register/checkPhone', data),
    checkMailExist: (data) => postparam('/register/checkExtMail/', data),
    resetPassword: (data) => post('/users/resetPassswd/', data),
    signUp: (data) => post('/users/ajaxreg/', data),
    getToken: (data) => get('/users/getToken', data),
    verifyAuthCode: (data) => postparam('/users/verify/2fa', data),
    getSystemConfig: () => get('/users/system/conf'),
    accountActivation: (data) => post('/users/send/restPasswd', data),
    setupPassword: (data) => post('/view/restPasswd/', data),
    getCompanyShopOverview: () => get('/users/manager/getCompanyShopOverview'),
    createMerchant: (data) => post('/users/manager/createMerchant', data),
    switchCompany: (data) => post('/users/manager/switchCompany', data)
};
