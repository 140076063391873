import React, { useState, useEffect } from 'react';
import * as intl from 'react-intl-universal';
import Button from "antd/lib/button";
import Image from "antd/lib/image";
import { FileOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
export default function FilePreview(props) {
    const { src = '', theme = 'default', className = '', style = {} } = props;
    const [isImage, setIsImage] = useState(true);
    const [isShow, setIsShow] = useState(false);
    const [fileName, setFileName] = useState('');
    useEffect(() => {
        if (src) {
            const type = src.split('.').pop().toLocaleLowerCase();
            const name = src.split('/').pop();
            setIsImage(/(png|jpe?g|gif|bmp|webp)$/.test(type));
            setFileName(name);
        }
    }, [src]);
    return (React.createElement("div", { className: `preview-container ${className}`, style: style }, theme === 'default' ? React.createElement("div", { className: 'default-theme' },
        React.createElement("div", { className: 'preview-content' }, renderFileContent(isImage, src, isShow, setIsShow)),
        React.createElement("div", { className: 'preview-operation' },
            isImage ? React.createElement(EyeOutlined, { className: 'view-btn', title: intl.get('Message.Preview'), onClick: () => setIsShow(true) }) : null,
            React.createElement(DownloadOutlined, { className: 'download-btn', title: intl.get('Message.Download'), onClick: () => download(src, fileName) }))) : React.createElement("div", { className: 'full-theme' },
        React.createElement("div", { className: 'preview-content' }, renderFileContent(isImage, src, isShow, setIsShow)),
        React.createElement("div", { className: 'preview-operation' },
            React.createElement("span", { className: 'file-name', title: fileName }, fileName),
            React.createElement("div", { className: 'tools-btn' },
                isImage ? React.createElement(Button, { className: 'view-btn', size: 'small', onClick: () => setIsShow(true) }, intl.get('Message.Preview')) : null,
                React.createElement(Button, { className: 'download-btn', size: 'small', onClick: () => download(src, fileName) }, intl.get('Message.Download')))))));
}
function renderFileContent(isImage, src, isShow, callback) {
    const preview = isShow ? {
        visible: isShow,
        onVisibleChange: (visible) => callback && callback(visible)
    } : false;
    return isImage ? React.createElement(Image, { className: 'image-preview', src: src, preview: preview }) : React.createElement(FileOutlined, { className: 'file-preview' });
}
function download(url, fileName) {
    if (!url)
        return;
    const aTag = document.createElement('a');
    aTag.setAttribute('type', 'hidden');
    aTag.target = '_blank';
    aTag.href = url;
    aTag.download = fileName || url;
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
}
