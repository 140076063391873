import * as React from 'react';
import Radio from "antd/lib/radio";
import StaticView from './StaticView';
export default class RadioView extends React.Component {
    constructor() {
        super(...arguments);
        this.adaptHandler = (val) => {
            if (!val)
                return '-';
            const { options = [] } = this.props;
            const display = options && options.filter(({ value }) => val === value);
            return (display && display.length && display[0].label) || '-';
        };
    }
    render() {
        const { staticView, disabled, readOnly, options, value } = this.props;
        return (staticView && disabled || readOnly)
            ? React.createElement(StaticView, { adaptFn: () => this.adaptHandler(value), disabled: disabled })
            : React.createElement(Radio.Group, Object.assign({}, this.props), (options || []).map((item, index) => (React.createElement(Radio, { key: `${item.value}-${index}`, value: item.value, disabled: item.disabled }, item.label))));
    }
}
RadioView.fieldName = 'radio';
RadioView.type = 'Radio';
