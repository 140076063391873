import { Base64 } from './file';
class Upload {
    constructor() {
        this.normalizeDecimalNumber = (value, times = 100000000000) => (Math.round(value * times) / times);
        this.imagePress = ({ src, type, quality, transBg }, callback) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const image = new Image();
            const ql = quality || 0.8;
            const pType = type || 'blob';
            const typeMatch = src.match(/[^:](\w+\/\w+)[^;\+]/);
            const fileType = typeMatch.length ? typeMatch[0] : null;
            const fType = transBg ? 'image/png' : (fileType || 'image/jpeg');
            image.setAttribute('crossOrigin', 'Anonymous');
            image.onload = () => {
                if (image.width > 1280) {
                    const maxWidth = 1280;
                    const aspectRatio = image.width / image.height;
                    const maxHeight = maxWidth / aspectRatio;
                    image.width = Math.floor(Math.min(Math.max(image.width, 0), maxWidth));
                    image.height = Math.floor(Math.min(Math.max(image.height, 0), maxHeight));
                }
                canvas.width = this.normalizeDecimalNumber(image.width);
                canvas.height = this.normalizeDecimalNumber(image.height);
                if (!transBg) {
                    ctx.fillStyle = '#fff';
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                }
                ctx.drawImage(image, 0, 0, image.width, image.height);
                const url = canvas.toDataURL(fType, ql);
                pType === 'blob' && callback(Base64.convertToBlob(url, fType), url);
                pType === 'base64' && callback(url);
            };
            image.src = src;
        };
    }
}
export default new Upload();
