import { Map } from 'immutable';
import { USER_CHECK_STATUS, GET_MAIN_MENU_LIST } from '../actions/user';
import { createAsyncActionHandler } from './creator';
const initial = Map({
    isCheckingStatus: true,
    isLoadingMenu: true,
    status: {},
    mainMenuList: []
});
const handlers = Object.assign(Object.assign({}, createAsyncActionHandler(USER_CHECK_STATUS, {
    init: (state) => state.merge({
        isCheckingStatus: true
    }),
    succeeded: (state, action) => state.merge({
        isCheckingStatus: false,
        status: action.payload
    }),
    failed: (state, action) => state.merge({
        isCheckingStatus: false,
        status: action.payload
    })
})), createAsyncActionHandler(GET_MAIN_MENU_LIST, {
    init: (state) => state.merge({
        isLoadingMenu: true,
        mainMenuList: []
    }),
    succeeded: (state, action) => state.merge({
        isLoadingMenu: false,
        mainMenuList: action.payload
    }),
    failed: (state) => state.merge({
        isLoadingMenu: false,
        mainMenuList: []
    })
}));
export const user = initial;
export default (state = user, action = {}) => {
    const fn = handlers[action.type];
    return fn ? fn(state, action) : state;
};
