import { put } from 'redux-saga/effects';
import { appReset } from '../actions/reset';
export const createEffect = (fn) => function* (action) {
    const { callback } = action;
    try {
        const res = yield fn(action);
        if (callback && callback.succeeded) {
            callback.succeeded(res);
        }
    }
    catch (err) {
        const { code } = err;
        if (code === 2000 || code === 2800)
            yield put(appReset());
        if (callback && callback.failed) {
            callback.failed(err);
        }
    }
};
