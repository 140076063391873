import { call, put, takeLatest } from 'redux-saga/effects';
import { common, user } from '@/api';
import { createEffect } from './creator';
import { USER_CHECK_STATUS, checkStatus, GET_MAIN_MENU_LIST, getMainMenuList } from '../actions/user';
export default [
    takeLatest(USER_CHECK_STATUS, createEffect(function* () {
        try {
            const data = yield call(user.checkStatus);
            const { ret_msg } = data;
            sessionStorage['shopid'] = ret_msg.shopid;
            sessionStorage['role_id'] = ret_msg.role_id;
            yield put(checkStatus.succeeded(ret_msg));
        }
        catch (err) {
            yield put(checkStatus.failed());
            throw err;
        }
    })),
    takeLatest(GET_MAIN_MENU_LIST, createEffect(function* () {
        try {
            const data = yield call(common.getMenuList);
            yield put(getMainMenuList.succeeded(data.ret_msg));
        }
        catch (err) {
            yield put(getMainMenuList.failed());
            throw err;
        }
    }))
];
