import { get, post, put } from '@/utils/http';
export default {
    getNewMesgCount: () => get('/work/getNewMesgCount/'),
    getNoticeList: (data) => get('/notice/getNoticelist', data),
    setNotice: (data) => put('/notice/setNotice', data),
    setAllnotice: () => post('/notice/setAllnotice'),
    delNotice: (data) => post('/notice/delNotice', data),
    getSetting: () => get('/notice/get/'),
    saveNotice: (data) => put('/notice/saveNotice/', data),
    getWorkOrderList: (data) => get('/work/getWorkOrderList', data),
    getWorkMessageList: (data) => get('/work/getWorkMessageList', data),
    sendMessage: (data) => post('/work/sendMessage', data),
    closeWorkOrder: (data) => put('/work/closeWorkOrder', data),
    createWorkOrder: (data) => post('/work/createWorkOrder', data),
    getSystem: (data) => post('/messageNotification/queryByPage', data),
};
