var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import i18n from '@/common/I18n';
import { Loading } from '@/common/components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { checkStatus } from '@/store/actions/user';
const mapStateToProps = (state) => ({
    isCheckingStatus: state.user.get('isCheckingStatus'),
    islogin: state.user.getIn(['status', 'islogin'])
});
const mapDispatchToProps = (dispatch) => (Object.assign({}, bindActionCreators({
    checkStatus: checkStatus.init
}, dispatch)));
export default () => (WrappedComponent) => {
    let Service = class Service extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isLocaleLoading: true
            };
            const { checkStatus: check } = this.props;
            check();
        }
        componentDidMount() {
            return __awaiter(this, void 0, void 0, function* () {
                yield i18n.init(i18n.getDefault());
                this.setState({ isLocaleLoading: false });
            });
        }
        render() {
            const { isCheckingStatus } = this.props;
            const { isLocaleLoading } = this.state;
            return (isCheckingStatus || isLocaleLoading)
                ? React.createElement(Loading, { debounce: 500 })
                : React.createElement(WrappedComponent, Object.assign({}, this.props));
        }
    };
    Service = __decorate([
        connect(mapStateToProps, mapDispatchToProps)
    ], Service);
    return Service;
};
