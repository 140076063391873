var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import * as intl from 'react-intl-universal';
import Clipboard from '@/common/components/Clipboard';
import Tooltip from "antd/lib/tooltip";
import { NavLink as Link } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
let StaticView = class StaticView extends React.Component {
    constructor() {
        super(...arguments);
        this.getTipTitle = (ctx) => ({
            'string': ctx,
            'object': ctx && ctx.props && typeof ctx.props.children === 'string' ? ctx.props.children : ''
        }[typeof ctx] || '');
    }
    render() {
        const { display, adaptFn, copy, clipboard, link, href, target, wrap } = this.props;
        const ctx = adaptFn ? (adaptFn(display) || '-') : display;
        const hasRouter = !!ctx && ctx !== '-' && (link || href);
        return (React.createElement("span", { className: `static-view ${wrap ? 'auto-break' : 'ellipsis'}`, style: { marginRight: 16 }, title: this.getTipTitle(ctx) },
            hasRouter
                ? [
                    !!link && React.createElement(Link, { key: 'link', to: link }, ctx),
                    !!href && React.createElement("a", { key: 'url', href: href, target: target ? target : '_blank' }, ctx)
                ]
                : ctx,
            copy && React.createElement(Tooltip, { placement: 'topLeft', title: intl.get('Global.Copy') },
                React.createElement("a", { onClick: () => clipboard.copy(ctx) },
                    " ",
                    React.createElement(CopyOutlined, null)))));
    }
};
StaticView = __decorate([
    Clipboard()
], StaticView);
export default StaticView;
