var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import * as intl from 'react-intl-universal';
import { common } from '@/api';
import Tabs from "antd/lib/tabs";
import Input from "antd/lib/input";
import Upload from "antd/lib/upload";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import Checkbox from "antd/lib/checkbox";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import FilePreview from '@/common/components/FilePreview';
import Icon from '@/components/Icon';
import AModal from '@/components/AntdCustomComponent/AModal';
const { TabPane } = Tabs;
const ManageTypeMap = {
    default: ['manage', 'upload'],
    onlyUpload: ['upload']
};
export default class ImageManager extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            localUploading: false,
            webUploading: false,
            tabType: this.props.managerType ? ManageTypeMap[this.props.managerType][0] : 'manage',
            isShow: false,
            selectedCount: this.props.selectedCount || 0,
            webImgUrl: '',
            webImgList: [],
            localImgUrl: '',
            localImgList: [],
            groupList: [],
            groupImgList: [],
            activeGroup: '',
            searchName: '',
            searching: false,
            param: {
                page: 1,
                pagesize: 20,
                totalCount: 0
            }
        };
        this.init = () => {
            if (this.props.managerType !== 'onlyUpload') {
                this.getImageGroup();
                this.getImageGroupList();
            }
        };
        this.getImageGroup = () => __awaiter(this, void 0, void 0, function* () {
            const { ret_code, ret_msg } = yield common.getImageGroup();
            if (ret_code === 200) {
                this.setState({ groupList: ret_msg, activeGroup: ret_msg[0] ? ret_msg[0].id : '0' });
            }
            else {
                message.error(intl.get('Global.NotificationWarningId', { field: `${window._traceid}` }) + intl.get('Global.NotificationWarningDescription'));
            }
        });
        this.getImageGroupList = () => __awaiter(this, void 0, void 0, function* () {
            const data = {
                params: {
                    gid: this.state.activeGroup,
                    name: this.state.searchName,
                    page: this.state.param.page,
                    pagesize: 15
                }
            };
            const { ret_code, ret_msg } = yield common.getImageGroupList(data);
            if (ret_code === 0) {
                const list = ret_msg.data.map((item) => {
                    item.active = false;
                    return item;
                });
                this.setState({
                    groupImgList: list,
                    totalCount: ret_msg.total_counts
                });
            }
            else {
                message.error(intl.get('Global.NotificationWarningId', { field: `${window._traceid}` }) + intl.get('Global.NotificationWarningDescription'));
            }
        });
        this.resetDefault = () => {
            this.setState({
                localUploading: false,
                webUploading: false,
                tabType: this.props.managerType ? ManageTypeMap[this.props.managerType][0] : 'manage',
                isShow: false,
                webImgUrl: '',
                webImgList: [],
                localImgUrl: '',
                localImgList: [],
                groupList: [],
                groupImgList: [],
                activeGroup: '',
                searchName: '',
                searching: false,
                param: {
                    page: 1,
                    pagesize: 20,
                    totalCount: 0
                }
            });
        };
        this.uploadStatusChange = (uploadKey, isUploading, callback) => {
            this.setState({
                [uploadKey]: isUploading
            }, () => {
                callback && callback();
            });
        };
        this.operationImageHandler = (action, type, data) => {
            const { limit = { count: 1 } } = this.props;
            const list = this.state[type];
            let { selectedCount = 0 } = this.state;
            let operationList = [];
            if (action === 'check') {
                const isCheck = !data.active;
                if (selectedCount >= limit.count && isCheck) {
                    message.error(intl.get('Upload.PicLimitHint', { num: limit.count }));
                    return;
                }
                operationList = list.map(item => {
                    if (item.id === data.id) {
                        item.active = isCheck;
                    }
                    return item;
                });
                isCheck ? selectedCount += 1 : selectedCount -= 1;
            }
            else {
                selectedCount = selectedCount - 1;
                operationList = list.filter((item) => data.id !== item.id);
            }
            this.setState({
                [type]: operationList,
                selectedCount
            });
        };
        this.valueChangeHandler = (key, e, callback) => {
            let value = null;
            if (typeof e === 'object') {
                e.persist();
                value = e.target.value;
            }
            else {
                value = e;
            }
            this.setState({ [key]: value }, () => {
                callback && callback();
            });
        };
        this.webUploadCheckHanlder = (webImgUrl) => {
            if (!webImgUrl)
                return;
            this.beforeUploadHandler({ size: 1024, type: 'image/*' }, [webImgUrl]) && this.uploadHandler('web', webImgUrl);
        };
        this.uploadHandler = (type, data) => {
            if (!data) {
                return;
            }
            const isLocalUpload = type === 'local';
            const uploadKey = isLocalUpload ? 'localUploading' : 'webUploading';
            const imageListType = isLocalUpload ? 'localImgList' : 'webImgList';
            const uploadFunction = {
                local: common.uploadMoreImage,
                web: common.uploadNetworkImage
            }[type];
            const originList = this.state[imageListType];
            let params = null;
            let list = [];
            if (isLocalUpload) {
                const { file } = data;
                const imageData = {};
                Array.isArray(file) ? file.forEach((item) => {
                    imageData['file'] = item;
                }) : imageData['file'] = file;
                params = imageData;
            }
            else {
                params = { image_url: this.state.webImgUrl };
            }
            this.uploadStatusChange(uploadKey, true, () => {
                uploadFunction(params).then((res) => {
                    const { ret_code, ret_msg } = res;
                    if (ret_code !== 1) {
                        message.error(intl.get('Global.NotificationWarningId', { field: `${window._traceid}` }) + intl.get('Global.NotificationWarningDescription'));
                        return;
                    }
                    const fileNameReg = /(?:[^']*\/)?([^\/\.]*)/;
                    let matchArr = isLocalUpload ? [] : ret_msg.match(fileNameReg);
                    list = isLocalUpload ? ret_msg.map((item) => {
                        matchArr = item.match(fileNameReg);
                        return {
                            id: matchArr[1],
                            name: matchArr[1],
                            path: item,
                            url: item
                        };
                    }) : [{
                            id: matchArr[1],
                            name: matchArr[1],
                            path: ret_msg,
                            url: ret_msg
                        }];
                    this.setState(Object.assign({ [imageListType]: originList.concat(list) }, (!isLocalUpload ? { webImgUrl: '' } : {})), () => {
                        this.operationImageHandler('check', imageListType, list[0]);
                    });
                }).finally(() => this.uploadStatusChange(uploadKey, false));
            });
        };
        this.beforeUploadHandler = (file, fileList) => {
            const { selectedCount } = this.state;
            const { limit } = this.props;
            const { count = 1, size = 2 } = limit;
            const fileLen = fileList.length || 0;
            if (fileLen > (count - selectedCount)) {
                message.error(intl.get('Upload.PicLimitHint', { num: count }));
                return false;
            }
            if (file.size > (size * 1024 * 1024)) {
                message.error(intl.get('Util.FileSize'));
                return false;
            }
            if ((file.type).slice(0, 5) !== 'image') {
                message.error(intl.get('Upload.OnlyPic'));
                return false;
            }
            return true;
        };
        this.modalToggleHandler = (action) => {
            const { callback } = this.props;
            if (action === 'ok') {
                const { groupImgList, webImgList, localImgList } = this.state;
                const allImages = JSON.parse(JSON.stringify([...groupImgList, ...webImgList, ...localImgList]));
                const selected = allImages.filter((item) => item.active);
                callback && callback(selected.map((item) => (Object.assign(Object.assign({}, item), { uid: item.id, name: item.name, status: 'done', url: item.path }))));
            }
            else {
                callback && callback([]);
            }
            this.resetDefault();
        };
        this.renderCentent = () => {
            const { tabType, localUploading, webUploading } = this.state;
            const { managerType = 'default' } = this.props;
            const tabList = ManageTypeMap[managerType];
            const renderTabPaneMap = {
                manage: this.renderManageTab(),
                upload: this.renderUploadTab()
            };
            const tabPaneTitleMap = {
                manage: intl.get('Upload.PicManage'),
                upload: intl.get('Upload.UploadPic')
            };
            return (React.createElement(Tabs, { activeKey: tabType, onChange: (e) => this.valueChangeHandler('tabType', e) }, tabList.map((item) => React.createElement(TabPane, { disabled: localUploading || webUploading, tab: tabPaneTitleMap[item], key: item }, renderTabPaneMap[item]))));
        };
        this.renderManageTab = () => {
            const { activeGroup, groupList, groupImgList } = this.state;
            return React.createElement(Tabs, { defaultActiveKey: activeGroup, tabPosition: 'left', onChange: (e) => this.valueChangeHandler('activeGroup', e, this.getImageGroupList) }, groupList.map((item) => React.createElement(TabPane, { tab: `${item.group_name} (${item.count})`, key: item.id }, this.renderImageItem('groupImgList', groupImgList))));
        };
        this.renderUploadTab = () => {
            const { limit } = this.props;
            const { count = 1, accept = 'image/*' } = limit;
            const { localImgList, webImgList, webImgUrl, localUploading, webUploading, selectedCount = 0 } = this.state;
            const list = localImgList.length ? localImgList.map((item) => (Object.assign(Object.assign({}, item), { uid: item.id, name: item.name, status: 'done', url: item.path }))) : [];
            const uploadButton = (React.createElement("div", { style: { fontSize: 16 } }, localUploading ? React.createElement(LoadingOutlined, null) : React.createElement(PlusOutlined, null)));
            const uploadBody = (React.createElement(Upload, { className: 'group-image-item', listType: 'picture-card', disabled: selectedCount >= count || localUploading || webUploading, accept: accept, multiple: true, beforeUpload: this.beforeUploadHandler, customRequest: (e) => this.uploadHandler('local', e), withCredentials: true, showUploadList: false }, uploadButton));
            return React.createElement("div", { className: 'upload-tab' },
                React.createElement("div", { className: 'uploader web-image-uploader' },
                    React.createElement("div", { className: 'uploader-label' },
                        intl.get('Upload.WebPic'),
                        ": "),
                    React.createElement("div", { className: 'uploader-container' },
                        React.createElement("div", { className: 'upload-url' },
                            React.createElement(Input, { type: 'text', className: 'height36', placeholder: intl.get('Upload.WebPicUrl'), value: webImgUrl, onChange: (e) => this.valueChangeHandler('webImgUrl', e) }),
                            React.createElement(Button, { className: 'upload-button height36', type: 'primary', disabled: localUploading || webUploading, icon: webUploading ? React.createElement(LoadingOutlined, null) : null, onClick: () => this.webUploadCheckHanlder(webImgUrl) }, intl.get('Global.Confirm'))),
                        this.renderImageItem('webImgList', webImgList))),
                React.createElement("div", { className: 'line' }),
                React.createElement("div", { className: 'uploader local-image-uploader' },
                    React.createElement("div", { className: 'uploader-label' },
                        intl.get('Upload.LocalPic'),
                        ": "),
                    React.createElement("div", { className: 'uploader-container' },
                        this.renderImageItem('localImgList', list, uploadBody),
                        selectedCount >= count ? React.createElement("span", { className: 'ant-btn-dangerous ant-btn-text' }, intl.get('Upload.PicLimitHint', { num: count })) : null)));
        };
        this.renderImageItem = (type, renderList, extra) => (React.createElement("div", { className: 'group-image-list' },
            renderList.map((item) => React.createElement("div", { className: 'group-image-container', key: type + item.id },
                React.createElement("div", { className: 'group-image-item' },
                    React.createElement(FilePreview, { src: item.url || item.path })),
                React.createElement("div", { className: 'tool-container' },
                    React.createElement(Checkbox, { className: 'group-image-check', checked: item.active, onChange: () => this.operationImageHandler('check', type, item) }),
                    type !== 'groupImgList' ? React.createElement(Icon, { icon: 'delete', className: 'group-image-delete', onClick: () => this.operationImageHandler('delete', type, item) }) : React.createElement(React.Fragment, null)))),
            extra));
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isShow, selectedCount } = this.state;
        if (nextProps.isShow && nextProps.isShow !== isShow) {
            this.setState({
                isShow: nextProps.isShow,
                selectedCount: isShow ? selectedCount : nextProps.selectedCount
            }, () => {
                nextProps.isShow && this.init();
            });
        }
    }
    componentDidMount() {
        this.state.isShow && this.init();
    }
    render() {
        const { isShow, localUploading, webUploading } = this.state;
        return (React.createElement(AModal, { className: 'manage-image-modal', width: 850, visible: isShow, title: false, closable: false, maskClosable: false, okText: intl.get('Global.Confirm'), cancelText: intl.get('Global.Cancel'), onCancel: () => this.modalToggleHandler('cancel'), onOk: () => this.modalToggleHandler('ok'), okButtonProps: { disabled: localUploading || webUploading }, cancelButtonProps: { disabled: localUploading || webUploading } }, this.renderCentent()));
    }
}
