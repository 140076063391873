var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { user } from '@/api';
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import message from "antd/lib/message";
import React from 'react';
import * as intl from 'react-intl-universal';
const MFAValidationModal = ({ open, verify = false, onCancel, onSuccess }) => {
    const [form] = Form.useForm();
    const onFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        const values = yield form.validateFields();
        if (values) {
            if (verify) {
                try {
                    const response = yield user.verifyAuthCode(values);
                    if (response.ret_code === 0) {
                        onSuccess(values.authenticateCode);
                    }
                    else {
                        message.error(intl.get('Util.InvalidationMFAMessage'));
                    }
                }
                catch (error) {
                    message.error(intl.get('Util.MFAValidationFailedMessage'));
                }
            }
            else {
                onSuccess(values.authenticateCode);
            }
        }
    });
    return (React.createElement(Modal, { width: 400, title: intl.get('Util.MFAValidationTitle') || '校验2FA', open: open, onCancel: onCancel, maskClosable: false, footer: false, afterClose: () => {
            form.resetFields();
        }, modalRender: (dom) => (React.createElement(Form, { labelCol: { span: 7 }, wrapperCol: { span: 14 }, form: form, name: "form_in_modal", onFinish: onFinish }, dom)) },
        React.createElement(Form.Item, { name: "authenticateCode", label: intl.get('Util.LoginWithMFATitleLabel') || 'Enter 2FA Code', rules: [
                { required: true, message: intl.get('Util.MFAValidationEmptyError') },
                { pattern: /^\d{6}$/, message: intl.get('Error.FormMFAIncorrectInputTip') }
            ] },
            React.createElement(Input.Password, { placeholder: intl.get('Util.Enter2FAPlaceholder'), maxLength: 6 })),
        React.createElement(Form.Item, { wrapperCol: { span: 24 }, style: { textAlign: 'center', marginTop: '2rem' } },
            React.createElement(Button, { type: "primary", htmlType: "submit" }, intl.get('Global.MfaVerifyCode')))));
};
export default MFAValidationModal;
