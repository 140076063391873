var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Input from "antd/lib/input";
import Tooltip from "antd/lib/tooltip";
import { default as InputN } from "antd/lib/input-number";
import Icon from '@/components/Icon';
import StaticView from './StaticView';
export default class Text extends React.Component {
    render() {
        const { value, disabled, staticView, initialValue, copy, readOnly, link, href, target, wrapBool, tooltip } = this.props;
        const input = React.createElement(Input, Object.assign({}, this.props));
        const ctx = (staticView && disabled || readOnly)
            ? React.createElement(StaticView, { display: value || initialValue || '-', copy: copy, link: link, href: href, wrap: wrapBool, target: target, disabled: disabled })
            : input;
        return tooltip ? React.createElement(Tooltip, { placement: 'topLeft', title: tooltip }, ctx) : ctx;
    }
}
Text.fieldName = 'text';
Text.type = 'Input';
export class Password extends React.Component {
    render() {
        const _a = this.props, { showEye = true } = _a, props = __rest(_a, ["showEye"]);
        return (React.createElement(Input.Password, Object.assign({ visibilityToggle: showEye, iconRender: (visible) => React.createElement(Icon, { icon: visible ? 'visible' : 'invisible' }) }, props)));
    }
}
Password.fieldName = 'password';
Password.type = 'Input';
export class Number extends React.Component {
    render() {
        const {} = this.props;
        return (React.createElement(Input, Object.assign({ type: 'number' }, this.props)));
    }
}
Number.fieldName = 'number';
Number.type = 'Input';
export class InputNumber extends React.Component {
    render() {
        console.info(this);
        const { width = '100%', height = '34px', controls = false, decimalSeparator = '.', disabled = false, precision = 0, maxLength, max, min } = this.props;
        return (React.createElement(InputN, Object.assign({}, this.props, { style: { width, height }, controls: controls, disabled: disabled, precision: precision, maxLength: maxLength, max: max, min: min })));
    }
}
InputNumber.fieldName = 'inputNumber';
InputNumber.type = 'Input';
