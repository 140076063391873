var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as intl from 'react-intl-universal';
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import InputNumber from "antd/lib/input-number";
import Row from "antd/lib/row";
import Select from "antd/lib/select";
import Space from "antd/lib/space";
import TimePicker from "antd/lib/time-picker";
const { Item, List } = Form;
import moment from 'moment';
export default class FormList extends React.Component {
    render() {
        const { name, initialValue = [], options, modeName } = this.props;
        return (React.createElement(List, { name: name, initialValue: initialValue }, (fields, { add, remove }) => (React.createElement(React.Fragment, null, fields.map((_a) => {
            var { key, name } = _a, restField = __rest(_a, ["key", "name"]);
            return (React.createElement(React.Fragment, null,
                modeName === 'week' &&
                    React.createElement(Row, { gutter: 8, key: key, style: { width: '95%' } },
                        React.createElement(Col, { span: 5 },
                            React.createElement(Item, Object.assign({ rules: [{ required: true, message: intl.get('Error.Required') }] }, restField, { name: [name, 'week_frame_list'] }),
                                React.createElement(Select, { mode: 'multiple', options: options, placeholder: intl.get('Global.Select'), allowClear: true }))),
                        React.createElement(Col, { span: 5 },
                            React.createElement(Item, Object.assign({ rules: [{ required: true, message: intl.get('Error.Required') }] }, restField, { name: [name, 'week_times'] }),
                                React.createElement(TimePicker.RangePicker, { format: 'HH:mm:ss', style: { height: '36px', width: '100%' } }))),
                        React.createElement(Col, { span: 3 },
                            React.createElement(Item, Object.assign({ rules: [{ required: true, message: intl.get('Error.Required') }] }, restField, { name: [name, 'amount_limit'] }),
                                React.createElement(InputNumber, { controls: false, min: 1, precision: 0, placeholder: intl.get('Payment.ChannelTransferLimit') || '限额', style: { width: '100%', height: 36 } }))),
                        React.createElement(Col, { span: 4 },
                            React.createElement(Space, null,
                                fields.length > 1 && key > 0 &&
                                    React.createElement(Button, { type: 'primary', danger: true, style: { height: 36 }, onClick: () => remove(name) }, intl.get('Global.Delete')),
                                React.createElement(Button, { type: 'primary', style: { height: 36 }, onClick: () => add({ id: '', warning_threshold: '' }) }, `+${intl.get('Payment.AddSubRecord') || '增加'}`)))),
                modeName === 'date' &&
                    React.createElement(Row, { gutter: 8, key: key, style: { width: '95%' } },
                        React.createElement(Col, { span: 7 },
                            React.createElement(Item, Object.assign({ rules: [{ required: true, message: intl.get('Error.Required') }] }, restField, { name: [name, 'dates'] }),
                                React.createElement(DatePicker.RangePicker, { format: 'YYYY-MM-DD HH:mm:ss', style: { height: '36px', width: '100%' }, showTime: true, disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day') }))),
                        React.createElement(Col, { span: 3 },
                            React.createElement(Item, Object.assign({ rules: [{ required: true, message: intl.get('Error.Required') }] }, restField, { name: [name, 'amount_limit'] }),
                                React.createElement(InputNumber, { controls: false, min: 1, precision: 0, placeholder: intl.get('Payment.ChannelTransferLimit') || '限额', style: { width: '100%', height: 36 } }))),
                        React.createElement(Col, { span: 4 },
                            React.createElement(Space, null,
                                fields.length > 1 && key > 0 &&
                                    React.createElement(Button, { type: 'primary', danger: true, style: { height: 36 }, onClick: () => remove(name) }, intl.get('Global.Delete')),
                                React.createElement(Button, { type: 'primary', style: { height: 36 }, onClick: () => add({ id: '', warning_threshold: '' }) }, `+${intl.get('Payment.AddSubRecord') || '增加'}`))))));
        })))));
    }
}
FormList.fieldName = 'formList';
