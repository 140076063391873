var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import message from "antd/lib/message";
import Result from "antd/lib/result";
import React from 'react';
import * as intl from 'react-intl-universal';
import { Form } from '@/components';
import i18n from '@/common/I18n';
import { user } from '@/api';
import AModal from '@/components/AntdCustomComponent/AModal';
export default class CreateMerchant extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            dataSource: {},
            userInfoUrl: '',
            policyUrl: '',
            isSubmitting: false
        };
        this.formValueChange = (values) => {
            this.setState({
                dataSource: Object.assign(Object.assign({}, this.state.dataSource), values)
            });
        };
        this.statementCheckHandler = (check) => {
            this.setState({
                dataSource: Object.assign(Object.assign({}, this.state.dataSource), { statement: check })
            }, () => {
                this.form.setFieldsValue({ statement: check ? 'check' : '' });
            });
        };
        this.onCancel = () => {
            const { companyState, setCompanyState, shopId } = this.props;
            setCompanyState(Object.assign(Object.assign({}, companyState), { isCreateMerchant: false, isSubmitted: false, defaultShopId: shopId }));
        };
        this.submitHandler = () => {
            const { companyState, setCompanyState } = this.props;
            this.form
                .validateFields()
                .then((values) => {
                this.setState({
                    isSubmitting: true,
                    dataSource: Object.assign(Object.assign({}, this.state.dataSource), values)
                }, () => {
                    const { companyname, certificate, identity_card } = this.state.dataSource;
                    const data = {
                        companyname,
                        img: {
                            certificate: certificate.map((item) => item.url || item.path),
                            identity_card: identity_card.map((item) => item.url || item.path)
                        }
                    };
                    user.createMerchant(data)
                        .then((res) => {
                        if (res.ret_code === 0) {
                            message.success(intl.get('Util.OperateSuccess'));
                            setCompanyState(Object.assign(Object.assign({}, companyState), { isSubmitted: true }));
                        }
                        else {
                            throw Error(res.ret_code);
                        }
                    })
                        .catch((error) => {
                        message.error(intl.get(`Error.${error.message}`) || intl.get('Global.NotificationWarningId', { field: `${window._traceid}` }) + intl.get('Global.NotificationWarningDescription'));
                    })
                        .finally(() => this.setState({ isSubmitting: false }));
                });
            })
                .catch((err) => err);
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const basicUrl = 'https://manager.intepay.io/topic/view?id=';
            const language = i18n.getDefault().toLowerCase();
            let userInfoUrl = '';
            let policyUrl = '';
            switch (language) {
                case 'zh-TW':
                    userInfoUrl = basicUrl + 'lJHiyJ6zmHgQ';
                    policyUrl = basicUrl + 'lzJmHzSy66gx';
                    break;
                case 'en-US':
                    userInfoUrl = basicUrl + 'lymJBiJNmJgU';
                    policyUrl = basicUrl + 'lNJN6ymizNgW';
                    break;
                default:
                    userInfoUrl = basicUrl + 'lyJSmyNHJNgX';
                    policyUrl = basicUrl + 'lBNHBSzBBNgP';
            }
            this.setState({
                userInfoUrl,
                policyUrl
            });
        });
    }
    render() {
        const { isSubmitting, dataSource, policyUrl, userInfoUrl } = this.state;
        const { companyState } = this.props;
        return (React.createElement(AModal, { visible: companyState.isCreateMerchant, footer: null, title: false, onCancel: () => {
                this.onCancel();
                this.form.resetFields();
                this.setState({
                    dataSource: Object.assign(Object.assign({}, this.state.dataSource), { statement: false })
                });
            }, closable: !companyState.isSubmitted }, companyState.isSubmitted ? (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                React.createElement("img", { src: "/public/images/intepay-logo.png", alt: "logo", title: "IntePay", width: '30%', style: { marginBottom: '2rem' } })),
            React.createElement(Result, { status: "success", subTitle: React.createElement("span", { style: { color: 'rgba(0, 0, 0, 0.85)' } }, intl.get('Register.RegisterSuccess')), extra: React.createElement(Button, { type: "primary", onClick: () => {
                        this.onCancel();
                    } }, intl.get('Global.Confirm')) }))) : (React.createElement("div", { className: "create-merchant" },
            React.createElement(Form, { extension: (form) => {
                    this.form = form;
                }, onValuesChange: this.formValueChange, buttons: [
                    {
                        text: !isSubmitting ? intl.get('Register.Submit') : intl.get('Register.Registering'),
                        disabled: isSubmitting,
                        callback: () => this.submitHandler(),
                        attributes: {
                            block: true,
                            type: 'primary'
                        }
                    }
                ], meta: [
                    {
                        name: 'companyname',
                        type: 'text',
                        label: intl.get('Register.CompanyName'),
                        placeholder: intl.get('Global.Input') + intl.get('Register.CompanyName'),
                        validators: ['required']
                    },
                    {
                        name: 'certificate',
                        type: 'upload',
                        label: `${intl.get('Register.Credentials')} ${intl.get('Register.CredentialsHint')}`,
                        placeholder: intl.get('Global.Input') + intl.get('Register.Credentials'),
                        validators: ['required.array'],
                        fieldProps: {
                            managerType: 'onlyUpload',
                            limit: {
                                count: 12
                            }
                        }
                    },
                    {
                        name: 'identity_card',
                        type: 'upload',
                        label: `${intl.get('Register.IDCard')} ${intl.get('Register.IDCardHint')}`,
                        placeholder: intl.get('Global.Input') + intl.get('Register.IDCard'),
                        validators: ['required.array'],
                        fieldProps: {
                            managerType: 'onlyUpload',
                            limit: {
                                count: 3
                            }
                        }
                    },
                    {
                        name: 'statement',
                        validators: ['required'],
                        custom: () => (React.createElement(Checkbox, { checked: dataSource.statement, onChange: (e) => this.statementCheckHandler(e.target.checked) },
                            React.createElement("span", { dangerouslySetInnerHTML: {
                                    __html: `${intl.get('Register.Statement')}<a href='${userInfoUrl}' target='_blank'>${intl.get('Register.UserInfo')}</a>${intl.get('Register.Add')}<a href='${policyUrl}' target='_blank'>${intl.get('Register.Policy')}</a>${intl.get('Register.ConfirmStatement')}`
                                } })))
                    }
                ] })))));
    }
}
