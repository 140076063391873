import { get, post, postquery, put, upload } from '@/utils/http';
export default {
    getGroupList: (data) => get('/companyCustomerGroup/list', data),
    updateGroup: (data) => put('/companyCustomerGroup', data),
    deleteGroup: (data) => postquery('/companyCustomerGroup/delete', data),
    addGroup: (data) => post('/companyCustomerGroup', data),
    getTagList: (data) => get('/company/customer/tag/list', data),
    updateTag: (data) => put('/company/customer/tag', data),
    deleteTag: (data) => postquery('/company/customer/tag/delete', data),
    addTag: (data) => post('/company/customer/tag', data),
    getCustomerList: (data) => post('/company/customer/list', data),
    exportCustomerInfo: (data) => post('/company/customer/export', Object.assign({ lang: 'zh-CN' }, data)),
    addCustomer: (data) => post('/company/customer/create', data),
    importCustomer: (data) => upload('/company/customer/excelImport', data),
    downloadTemplate: (data) => get('/company/customer/customerExcelTemplate', Object.assign({ language: 'zh_cns' }, data)),
    updateCustomer: (data) => put('/company/customer', data),
    updateCustomerGroups: (data) => put('/company/customer/batch/group/update', data),
    updateCustomerTags: (data) => put('/company/customer/batch/tag/update', data),
};
