import * as React from 'react';
import Loading from '../Loading';
export default (loader) => class Lazy extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            loaded: null,
        };
    }
    componentDidMount() {
        this.mounted = true;
        loader()
            .then((loaded) => this.mounted && this.setState({ loaded }))
            .catch((err) => console.error(err));
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    render() {
        const { loaded } = this.state;
        if (loaded) {
            const Component = loaded.default;
            return React.createElement(Component, Object.assign({}, this.props));
        }
        return React.createElement(Loading, null);
    }
};
