import React, { createContext, useState, useCallback } from 'react';
export const AuthenticationContext = createContext(null);
const AuthenticationProvider = ({ children }) => {
    const [isVerified, setVerified] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [lastAuthTime, setLastAuthTime] = useState(null);
    const [timer, setTimer] = useState(null);
    const [subscribers, setSubscribers] = useState(new Set());
    const startTimer = (callback, delay) => {
        clearTimer();
        const newTimer = setTimeout(() => {
            callback();
            subscribers.forEach(subscriber => subscriber());
            clearTimer();
            setLastAuthTime(null);
        }, delay);
        setTimer(newTimer);
    };
    const clearTimer = () => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
    };
    const subscribe = useCallback((callback) => {
        setSubscribers(prevSubscribers => new Set(prevSubscribers).add(callback));
    }, []);
    const unsubscribe = useCallback((callback) => {
        setSubscribers(prevSubscribers => {
            const newSubscribers = new Set(prevSubscribers);
            newSubscribers.delete(callback);
            return newSubscribers;
        });
    }, []);
    return (React.createElement(AuthenticationContext.Provider, { value: {
            isVerified, setVerified, showModal, setShowModal, lastAuthTime, setLastAuthTime, timer, startTimer, clearTimer, subscribe, unsubscribe
        } }, children));
};
export default AuthenticationProvider;
