import * as React from 'react';
import { Converter } from 'showdown';
import RichTextEditor from '@/common/components/RtEditor';
import StaticView from './StaticView';
export default class RichText extends React.Component {
    constructor() {
        super(...arguments);
        this.richTextHandler = (content) => {
            const { onChange } = this.props;
            onChange(content);
        };
        this.getContent = (value) => (value.replace(/<[^>]*/g, '').split('>') || []).filter((e) => !!e);
        this.getThumbnailText = (value) => (`${this.getContent(value).slice(0, 2).join(',')}...`);
    }
    render() {
        const { value, format, extra, disabled, staticView, thumbnail, wrap, target, onCall } = this.props;
        return (React.createElement("div", { style: { maxWidth: 600 } }, (staticView && disabled)
            ? React.createElement(StaticView, { wrap: wrap, display: value, adaptFn: (value) => {
                    if (thumbnail) {
                        return React.createElement("div", null, this.getThumbnailText(value));
                    }
                    else {
                        const content = format === 'markdown' ? new Converter().makeHtml(value) : value;
                        const contentSelf = target === '_self' ? content.replace('_blank', '_self') : content;
                        return React.createElement("a", { className: 'retContent', dangerouslySetInnerHTML: { __html: contentSelf } });
                    }
                } })
            : React.createElement(RichTextEditor, { onChange: this.richTextHandler, value: value, tips: extra, imgOnCall: onCall })));
    }
}
