var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { is, List } from 'immutable';
export function isEqual(value, defaultValue, ignoreCasing = true) {
    return (typeof value === 'string' && typeof defaultValue === 'string' && ignoreCasing)
        ? value.toLowerCase() === defaultValue.toLowerCase()
        : is((value ? List(value) : value), (defaultValue ? List(defaultValue) : defaultValue));
}
export default ({ validator, message }, _a = {}, outCallback) => {
    var { defaultValue, ignoreCasing } = _a, rest = __rest(_a, ["defaultValue", "ignoreCasing"]);
    return ({
        validator: (rule, value, callback) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (isEqual(value, defaultValue, ignoreCasing)) {
                    callback();
                }
                else {
                    const res = yield validator(value, rest);
                    callback();
                    outCallback && outCallback(res);
                }
            }
            catch (err) {
                callback(err);
                outCallback && outCallback();
            }
        }),
        message
    });
};
