function generateRouterEntryList(routerConfigList, prevPaths) {
    return routerConfigList.map(({ component, href, title, children, name }) => {
        const current = { title, component, href, name };
        if (!children && title && href && component) {
            return { [href]: [...prevPaths, current] };
        }
        else if (children && children.length > 0) {
            return [
                !!component && { [href]: [...prevPaths, current] },
                ...generateRouterEntryList(children, [...prevPaths, current])
            ].filter(_ => !!_);
        }
        else {
            return [];
        }
    });
}
function flatCascadeList(cascadeList) {
    let obj = {};
    for (const item of cascadeList) {
        if (Array.isArray(item)) {
            obj = Object.assign(Object.assign({}, obj), flatCascadeList(item));
        }
        else if (typeof item === 'object') {
            obj = Object.assign(Object.assign({}, obj), item);
        }
    }
    return obj;
}
export default (routeConfig) => {
    const routerEntryList = generateRouterEntryList(routeConfig, []);
    return flatCascadeList(routerEntryList);
};
