var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as moment from 'moment';
import DatePicker from "antd/lib/date-picker";
import StaticView from './StaticView';
export default class DatePickerField extends React.Component {
    constructor() {
        super(...arguments);
        this.adaptHandler = () => {
            const { value } = this.props;
            return (Array.isArray(value) ? value.filter(Boolean).join(' ~ ') : value) || '-';
        };
        this.changeHandler = (date, dateString) => {
            const { onChange, format = 'YYYY-MM-DD HH:mm:ss' } = this.props;
            onChange(dateString || (Array.isArray(date) ? date.map((item) => item.format(format)) : date.format(format)));
        };
        this.formatValueHandler = (value) => {
            const { format = 'YYYY-MM-DD HH:mm:ss' } = this.props;
            return !value || moment(value).format(format) === 'Invalid date' ? null : moment(value, format);
        };
    }
    render() {
        const _a = this.props, { pickerType = 'single', value, disabled, size = 'large', format = 'YYYY-MM-DD HH:mm:ss', staticView } = _a, props = __rest(_a, ["pickerType", "value", "disabled", "size", "format", "staticView"]);
        const remainingProps = Object.assign({}, props);
        const PickerContainer = pickerType === 'range' ? DatePicker.RangePicker : DatePicker;
        const formatValue = Array.isArray(value) ? value.map(this.formatValueHandler) : this.formatValueHandler(value);
        if (remainingProps.placeholder === undefined) {
            delete remainingProps.placeholder;
        }
        return ((staticView && disabled) ? React.createElement(StaticView, { display: this.adaptHandler() }) : React.createElement(PickerContainer, Object.assign({}, remainingProps, {
            defaultValue: formatValue,
            value: formatValue
        }, { size: size, format: format, onChange: this.changeHandler })));
    }
}
DatePickerField.fieldName = 'datePicker';
