var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import url from 'url';
import request from '@/common/utils/request';
import { merge } from '@/common/I18n/utils';
export default (language) => __awaiter(void 0, void 0, void 0, function* () {
    const common_cdn = url.resolve(window.cdn_host, window.translation_host);
    console.log('cdn_host: ' + window.cdn_host);
    console.log('translation_host: ' + window.translation_host);
    let locale = {};
    switch (language) {
        case 'en-US':
            const [en_us, common_en_us] = yield Promise.all([request(`${common_cdn}/inte_manager/en_us.json`), request(`${common_cdn}/common/en_us.json`)]);
            locale = merge(en_us, common_en_us);
            break;
        case 'zh-CN':
        case 'zh':
            const [zh_cns, common_zh_cns] = yield Promise.all([request(`${common_cdn}/inte_manager/zh_cns.json`), request(`${common_cdn}/common/zh_cns.json`)]);
            locale = merge(zh_cns, common_zh_cns);
            break;
        case 'zh-TW':
            const [zh_cnt, common_zh_cnt] = yield Promise.all([request(`${common_cdn}/inte_manager/zh_cnt.json`), request(`${common_cdn}/common/zh_cnt.json`)]);
            locale = merge(zh_cnt, common_zh_cnt);
            break;
    }
    console.log('common_cdn' + common_cdn + 'locale' + locale);
    return { [language]: locale };
});
