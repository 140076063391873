import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Launcher from '@/pages/App/Launcher/Login';
import i18n from '@/common/I18n';
import routes from '@/pages/Login/routes';
import loaders from './loaders';
export default class Login extends React.Component {
    render() {
        const lang = i18n.getDefault();
        return (React.createElement(Router, null,
            React.createElement(Launcher, { lang: lang, routesConfig: { routes, loaders } })));
    }
}
