import { get, post, postparam } from '@/utils/http';
export default {
    getTimezone: () => get('/setting/getTimezone/'),
    setTimezone: (data) => post('/setting/setTimezone', data),
    getUserData: () => get('/users/getUserData'),
    updateUserData: (data) => post('/users/updateUserData', data),
    updatePassword: (data) => postparam('/users/updatePasswd', data),
    getCurrentShopInfo: () => get('/shop/getCurrentShopInfo/'),
    updateShop: (data) => postparam('/shop/updateShop', data),
    addAccount: (data) => post('/users/addAccount', data),
    getCompanysData: () => get('/companys/getCompanysData'),
    getAccountList: (data) => post('/users/accountList', data),
    resetAccountPassword: (data) => post('/users/manager/resetPasswd', data),
    resetAccountEmail: (data) => post('/users/manager/email', data),
    resetAccountPhone: (data) => post('/users/manager/phone', data),
    unlockAccount: (data) => post('/users/manager/unlock', data),
    changeAccountStatus: (data) => post('/users/manager/status', data),
    deleteAccount: (data) => post('/users/manager/delete', data),
    updateAccountRole: (data) => post('/users/manager/role', data),
    getRoleList: (data) => post('/role/list', data),
    addRole: (data) => post('/role/add', data),
    deleteRole: (data) => post('/role/delete', data),
    updateRole: (data) => post('/role/update', data),
    getRoleMenuList: (data) => get('/role/menu/list', data),
    updateRoleMenuList: (data) => post('/role/menu/update', data),
    getAllRole: () => get('/role/total/list'),
    verifyPassword: (data) => post('/users/manager/verifyPassword', data),
    verifyMfaToken: (data) => post('/users/manager/verifyMfaToken', data),
};
