var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import * as intl from 'react-intl-universal';
import Alert from "antd/lib/alert";
import Editor from './Editor';
import { upload } from '@/utils/http';
import i18n from '@/common/I18n';
import uploadUtils from '../../utils/upload';
import Loading from '../Loading';
import helper from './utils';
function uploadHandler(blobInfo, success, failure) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (blobInfo.blob().size > 1024 * 1024 * 2) {
                failure(intl.get('Util.FileSize').d('文件大小不得大于 2MB'));
                return;
            }
            const core = new FileReader();
            core.onload = function (e) {
                uploadUtils.imagePress({ src: e.target.result, quality: 0.3 }, (file) => __awaiter(this, void 0, void 0, function* () {
                    const res = yield upload('/images/upload_more_images', { file });
                    if (res && res.ret_code === 1) {
                        success(res.ret_msg[0]);
                    }
                    else {
                        failure(intl.get('Util.UploadFailed'));
                    }
                }));
            };
            core.readAsDataURL(blobInfo.blob());
        }
        catch (err) {
            failure(intl.get('Util.UploadFailed'));
        }
    });
}
export default class RichText extends React.Component {
    constructor(props) {
        super(props);
        this.changeHandle = (value) => this.mounted && this.setState({ value }, () => {
            const { onChange } = this.props;
            onChange && onChange(value);
        });
        this.uploadHandler = () => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', helper.getFileTypes(RichText.fileTypes));
            input.onchange = () => __awaiter(this, void 0, void 0, function* () { return false; });
            input.click();
        };
        this.initHandler = (ed) => {
            const { value = '', extension } = this.props;
            this.editor = ed;
            extension && extension(ed);
            this.mounted && this.setState({ isLoading: false, value });
        };
        this.initUpload = (ed) => {
            ed.addButton('browse', {
                title: intl.get('Component.UploadFile'),
                icon: 'browse',
                onclick: this.uploadHandler
            });
        };
        this.floatingHandler = (floating) => {
            if (!floating) {
                const ed = this.editor;
                ed.delegates.focusin();
                ed.contentWindow.focus();
            }
            this.mounted && this.setState({ floating });
        };
        this.imgUploadHandler = (blobInfo, success, failure) => {
            const { imgOnCall } = this.props;
            this.setState({ isLoading: true });
            imgOnCall && imgOnCall(true);
            uploadHandler(blobInfo, (data) => {
                this.setState({ isLoading: false });
                imgOnCall && imgOnCall(false);
                success(data);
            }, failure).catch(() => {
                this.setState({ isLoading: false });
                imgOnCall && imgOnCall(false);
            });
            this.setState({ isLoading: false });
        };
        this.state = {
            isLoading: true,
            floating: true,
            value: ''
        };
    }
    static get universal() {
        const lang = i18n.getDefault();
        return ['zh-CN', 'zh-TW', 'ko-KR'].includes(lang) ? window.config.js_file(`/tinymce/editor_${lang}.js`) : null;
    }
    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    UNSAFE_componentWillReceiveProps({ value }) {
        if (this.props.value !== value)
            this.mounted && this.setState({ value });
    }
    render() {
        const { value, isLoading, floating } = this.state;
        const { tips } = this.props;
        return (React.createElement(Loading, { enabled: isLoading },
            tips && React.createElement(Alert, { message: tips, showIcon: true }),
            React.createElement("div", { className: 'rich-text-float', style: {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    display: floating ? 'block' : 'none',
                    zIndex: 5
                }, onClick: () => this.floatingHandler(false) }),
            React.createElement(Editor, { value: value, init: {
                    baseURI: window.config.js_file('/'),
                    plugins: 'link textcolor image imagetools lists colorpicker code paste',
                    toolbar: 'formatselect | styleselect | undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | forecolor backcolor | image link | code',
                    paste_data_images: true,
                    min_height: 200,
                    min_width: 500,
                    language_url: RichText.universal,
                    file_picker_types: 'image',
                    theme: 'modern',
                    branding: false,
                    menubar: false,
                    style_formats: [
                        { title: intl.get('Editor.FirstIndent'), block: 'p', styles: { textIndent: '30px' } },
                        {
                            title: intl.get('Editor.PictureAdapt'),
                            selector: 'img',
                            styles: { minWidth: '100%', minHeight: '100%', verticalAlign: 'middle' }
                        },
                        {
                            title: intl.get('Editor.Font'),
                            items: [
                                { title: '8pt', inline: 'span', styles: { fontSize: '8px' } },
                                { title: '10pt', inline: 'span', styles: { fontSize: '10px' } },
                                { title: '12pt', inline: 'span', styles: { fontSize: '12px' } },
                                { title: '14pt', inline: 'span', styles: { fontSize: '14px' } },
                                { title: '18pt', inline: 'span', styles: { fontSize: '18px' } },
                                { title: '24pt', inline: 'span', styles: { fontSize: '24px' } },
                                { title: '36pt', inline: 'span', styles: { fontSize: '36px' } }
                            ]
                        },
                        {
                            title: intl.get('Editor.LineSpace'),
                            block: 'p',
                            items: [
                                { title: '1.0', block: 'p', styles: { lineHeight: '1' } },
                                { title: '1.5', block: 'p', styles: { lineHeight: '1.5' } },
                                { title: '2.0', block: 'p', styles: { lineHeight: '2' } },
                                { title: '2.5', block: 'p', styles: { lineHeight: '2.5' } },
                                { title: '3.0', block: 'p', styles: { lineHeight: '3' } }
                            ]
                        },
                        {
                            title: intl.get('Editor.RowHeight'),
                            block: 'p',
                            items: [
                                { title: '8pt', block: 'p', styles: { lineHeight: '8px' } },
                                { title: '10pt', block: 'p', styles: { lineHeight: '10px' } },
                                { title: '12pt', block: 'p', styles: { lineHeight: '12px' } },
                                { title: '14pt', block: 'p', styles: { lineHeight: '14px' } },
                                { title: '18pt', block: 'p', styles: { lineHeight: '18px' } },
                                { title: '24pt', block: 'p', styles: { lineHeight: '24px' } },
                                { title: '36pt', block: 'p', styles: { lineHeight: '36px' } }
                            ]
                        },
                        {
                            title: intl.get('Editor.PictureMargin'),
                            items: [
                                { title: '5px', selector: 'img', styles: { margin: '5px' } },
                                { title: '10px', selector: 'img', styles: { margin: '10px' } },
                                { title: '15px', selector: 'img', styles: { margin: '15px' } },
                                { title: '24px', selector: 'img', styles: { margin: '24px' } },
                                { title: '36px', selector: 'img', styles: { margin: '36px' } }
                            ]
                        }
                    ],
                    images_upload_handler: this.imgUploadHandler,
                    init_instance_callback: this.initHandler,
                    content_style: 'li{display: list-item;} p{margin-bottom:0;margin-top:0;}',
                }, onEditorChange: this.changeHandle, onFloating: this.floatingHandler })));
    }
}
RichText.fileTypes = ['pdf', 'doc', 'docx'];
