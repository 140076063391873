import * as React from 'react';
import Input from "antd/lib/input";
import Tooltip from "antd/lib/tooltip";
import StaticView from './StaticView';
export default class TextArea extends React.Component {
    render() {
        const { value, disabled, staticView, initialValue, copy, readOnly, link, href, target, wrapBool, tooltip } = this.props;
        const input = React.createElement(Input.TextArea, Object.assign({ autoSize: { minRows: 1, maxRows: 3 } }, this.props));
        const ctx = (staticView && disabled || readOnly)
            ? React.createElement(StaticView, { display: value || initialValue || '-', copy: copy, link: link, href: href, wrap: wrapBool, target: target })
            : input;
        return tooltip ? React.createElement(Tooltip, { placement: 'topLeft', title: tooltip }, ctx) : ctx;
    }
}
TextArea.fieldName = 'textarea';
TextArea.type = 'TextArea';
