import * as React from 'react';
import Result from "antd/lib/result";
import { ResultProps } from "antd";
export function ErrorField(props) {
    const { config } = props;
    let status = '404';
    let subTitle = 'Not Found';
    switch (config.status) {
        case '403':
            status = '403';
            subTitle = 'Not Authorized';
            break;
        case '500':
            status = '500';
            subTitle = 'Server Error';
            break;
        default:
    }
    return (React.createElement("div", Object.assign({}, props, { className: `result-page ${props.className ? props.className : ''}` }),
        React.createElement(Result, Object.assign({}, config, { status: status, title: config.title ? config.title : status, subTitle: config.subTitle ? config.subTitle : subTitle }))));
}
export default (Child) => class extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            err: null
        };
    }
    componentDidCatch(err) {
        this.setState({ err });
    }
    render() {
        const { err } = this.state;
        return Child.config || err
            ? React.createElement(ErrorField, Object.assign({}, Object.assign(Object.assign({}, this.props), { config: Child.config || err })))
            : React.createElement(Child, Object.assign({}, this.props));
    }
};
