import ModalContainer from './Modal';
import Dialog from './Dialog';
export { Dialog };
class ModalHelper {
    constructor() {
        this.index = 1;
        this.getIndex = () => {
            this.index++;
            if (this.index >= 1000) {
                this.index = 1;
            }
            let nn = `${this.index}`;
            while (nn.length < 3) {
                nn = `0${nn}`;
            }
            return nn;
        };
        this.uuid = () => `${~~(Date.now() / 1000)}${this.getIndex()}`;
        this.show = (option) => {
            const uuid = this.uuid();
            ModalContainer.showDialog({
                id: uuid,
                option
            });
            return uuid;
        };
        this.close = (id) => {
            if (id) {
                ModalContainer.removeDialog(id);
            }
            else {
                ModalContainer.removeAll();
            }
        };
    }
}
export default new ModalHelper();
