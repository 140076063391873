var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import { get } from '@/utils/http';
import ASelect from '@/components/AntdCustomComponent/ASelect';
const Option = Select.Option;
const InputGroup = Input.Group;
function Phone(props) {
    const { name, lang, placeholder = '', disabled = false } = props;
    const [codes, setCodes] = useState([]);
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            let codeUrl = '';
            switch (lang) {
                case 'zh-CN':
                    codeUrl = '/public/json/cnCodes.json';
                    break;
                case 'zh-TW':
                    codeUrl = '/public/json/twCodes.json';
                    break;
                default:
                    codeUrl = '/public/json/enCodes.json';
            }
            const codeData = yield get(codeUrl);
            if (codeData.length) {
                setCodes(codeData);
            }
        }))();
    }, []);
    return (React.createElement(InputGroup, { className: 'phone-input', compact: true },
        React.createElement(Row, null,
            React.createElement(Col, { span: 8 },
                React.createElement(Form.Item, { name: [name, 'code'], initialValue: '+86', noStyle: true },
                    React.createElement(ASelect, { className: 'code-select', dropdownMatchSelectWidth: false, dropdownStyle: { width: 'auto' }, disabled: disabled }, codes.map(({ name: codeName, value: codeValue, key }) => (React.createElement(Option, { key: key, value: codeValue }, `(${codeValue}) ${codeName}`)))))),
            React.createElement(Col, { span: 16 },
                React.createElement(Form.Item, { name: [name, 'value'], noStyle: true },
                    React.createElement(Input, { className: 'number-input', placeholder: placeholder || 'Please enter the phone number', disabled: disabled }))))));
}
export default Phone;
