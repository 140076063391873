export const createAction = (type) => (payload) => ({
    type,
    payload
});
export const createAsyncAction = (type) => ({
    init: (payload, callback) => ({
        type,
        payload,
        callback
    }),
    succeeded: (payload, callback) => ({
        type: `${type}_SUCCEEDED`,
        payload,
        callback
    }),
    failed: (payload, callback) => ({
        type: `${type}_FAILED`,
        payload,
        callback
    })
});
