var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AuthenticationContext } from '@/store/context/authenticationContext';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Tooltip from "antd/lib/tooltip";
import * as React from 'react';
import MFAValidationModal from './../../MFAValidationModal';
import StaticView from './StaticView';
let apiResponse = {};
export default class EncryptableTextArea extends React.Component {
    constructor(props) {
        super(props);
        this.handleTimerExpire = () => {
            this.context.setVerified(false);
            this.setState({
                visible: false,
                displayValue: this.props.encryptedValue
            });
        };
        this.toggleVisibility = () => {
            const currentTime = Date.now();
            const lastAuthTime = this.context.lastAuthTime;
            const isVerified = this.context.isVerified;
            const threeMinutes = 180000;
            if (!this.state.visible) {
                if ((!lastAuthTime || currentTime - lastAuthTime > threeMinutes) && !isVerified) {
                    this.setState({ showModal: true });
                }
                else {
                    let displayValue = apiResponse && apiResponse[this.props.name] ? apiResponse[this.props.name] : this.state.value;
                    if (this.props.isDigitalOrPayout) {
                        const paramsMap = apiResponse[this.props.responseAttribute].reduce((acc, param) => {
                            acc[param.param_key] = param.param_value;
                            return acc;
                        }, {});
                        displayValue = paramsMap[this.props.name] || this.state.value;
                    }
                    this.setState((prevState) => ({
                        visible: !prevState.visible,
                        displayValue: !prevState.visible ? displayValue : this.state.encryptedValue
                    }), this.startTimer);
                }
            }
            else {
                this.setState((prevState) => ({
                    visible: !prevState.visible,
                    displayValue: !prevState.visible ? this.state.value : this.state.encryptedValue
                }));
            }
        };
        this.startTimer = () => {
            if (!this.context.lastAuthTime) {
                this.context.startTimer(() => { }, 180000);
            }
        };
        this.handleOk = (authCode) => __awaiter(this, void 0, void 0, function* () {
            if (typeof this.state.originApi === 'function') {
                const id = this.props.stateValues;
                console.log('Calling originApi with id:', id, 'and authCode:', authCode);
                const originApiResponse = yield this.state.originApi(id, authCode);
                this.setState({
                    value: originApiResponse[this.props.name]
                }, () => {
                    this.toggleVisibility();
                });
                if (this.props.isDigitalOrPayout) {
                    const paramsMap = originApiResponse[this.props.responseAttribute].reduce((acc, param) => {
                        acc[param.param_key] = param.param_value;
                        return acc;
                    }, {});
                    this.setState({
                        value: paramsMap[this.props.name] || ''
                    }, () => {
                        this.toggleVisibility();
                    });
                }
                apiResponse = originApiResponse;
            }
            const currentTime = Date.now();
            this.setState(() => ({
                visible: true,
                displayValue: this.state.value,
                showModal: false
            }), this.startTimer);
            this.context.setVerified(true);
            this.context.setLastAuthTime(currentTime);
            this.context.setShowModal(false);
        });
        this.handleCancel = () => {
            this.setState({ showModal: false });
        };
        this.handleEncryptChange = (e) => {
            this.setState({ displayValue: '' });
            const newValue = e.target.value;
            if (this.props.onChange) {
                if (this.context.isVerified) {
                    this.setState({ displayValue: newValue, value: newValue, visible: true });
                    this.props.onChange(e);
                }
                else {
                    this.setState({ displayValue: newValue });
                    this.props.onChange(e);
                }
            }
        };
        this.handleNormalChange = (e) => {
            const newValue = e.target.value;
            this.setState({ displayValue: newValue, value: newValue });
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        };
        this.handleFocus = () => {
            if (!this.state.visible) {
                this.setState({
                    displayValue: ''
                });
            }
        };
        this.handleBlur = (e) => {
            if (!this.state.visible) {
                if (this.state.value == e.target.value || !this.context.isVerified) {
                    this.setState({
                        displayValue: this.state.encryptedValue
                    });
                }
                else {
                    this.setState({
                        displayValue: this.state.encryptedValue,
                        value: e.target.value
                    });
                }
            }
            else {
                this.setState({
                    displayValue: this.state.value
                });
            }
        };
        this.state = {
            value: this.props.value,
            encryptedValue: this.props.encryptedValue,
            visible: false,
            displayValue: props.encryptedValue || '',
            showModal: false,
            isRegistered: this.props.isRegistered,
            imageQrUri: this.props.imageQrUri,
            isValid: this.props.isValid,
            originApi: this.props.originApi
        };
    }
    componentDidMount() {
        this.context.subscribe(this.handleTimerExpire);
    }
    componentWillUnmount() {
        this.context.unsubscribe(this.handleTimerExpire);
        if (this.context.timer) {
            clearTimeout(this.context.timer);
        }
    }
    render() {
        const _a = this.props, { disabled, staticView, initialValue, copy, readOnly, link, href, target, wrapBool, tooltip, isValid, style } = _a, resetProps = __rest(_a, ["disabled", "staticView", "initialValue", "copy", "readOnly", "link", "href", "target", "wrapBool", "tooltip", "isValid", "style"]);
        const { visible, displayValue, showModal, isRegistered, imageQrUri, value } = this.state;
        const textAreaInput = (React.createElement(Input.TextArea, { value: displayValue || initialValue || '', autoSize: { minRows: 1, maxRows: 3 }, onChange: this.handleEncryptChange, onFocus: this.handleFocus, onBlur: this.handleBlur }));
        const textInput = React.createElement(Input, { value: value, onChange: this.handleNormalChange });
        const eyeIcon = visible ? React.createElement(EyeOutlined, null) : React.createElement(EyeInvisibleOutlined, null);
        const ctx = (staticView && disabled) || readOnly ? (React.createElement(StaticView, Object.assign({ display: displayValue || initialValue || '-', copy: copy, link: link, href: href, wrap: wrapBool, target: target }, resetProps))) : (React.createElement("div", { style: Object.assign({ position: 'relative', display: 'flex' }, style) },
            isValid ? textAreaInput : textInput,
            isValid && (React.createElement(Button, { icon: eyeIcon, onClick: this.toggleVisibility, style: { position: 'absolute', right: 0, top: 0, height: '100%' } }))));
        return (React.createElement(React.Fragment, null,
            tooltip ? (React.createElement(Tooltip, { placement: "topLeft", title: tooltip }, ctx)) : (ctx),
            React.createElement(MFAValidationModal, { open: showModal, verify: true, onCancel: this.handleCancel, onSuccess: this.handleOk })));
    }
}
EncryptableTextArea.fieldName = 'encryptabletextarea';
EncryptableTextArea.type = 'EncryptableTextArea';
EncryptableTextArea.contextType = AuthenticationContext;
