import Captcha from './Captcha';
import Checkbox from './Checkbox';
import Custom from './Custom';
import DatePicker from './DatePicker';
import EncryptableTextArea from './EncryptableTextArea';
import FormList from './Formlist';
import Phone from './Phone';
import Radio from './Radio';
import RtText from './RtText';
import Select from './Select';
import SelectDropDown from './SelectDropDown';
import Slider from './Slider';
import Text, { InputNumber, Number, Password } from './Text';
import TextArea from './TextArea';
import TimePicker from './TimePicker';
import TreeSelect from './TreeSelect';
import Upload from './Upload';
import UploadLocal from './UploadLocal';
export default {
    text: Text,
    textarea: TextArea,
    encryptabletextarea: EncryptableTextArea,
    password: Password,
    number: Number,
    inputNumber: InputNumber,
    select: Select,
    radio: Radio,
    upload: Upload,
    uploadLocal: UploadLocal,
    captcha: Captcha,
    phone: Phone,
    checkbox: Checkbox,
    rttext: RtText,
    treeselect: TreeSelect,
    datePicker: DatePicker,
    timePicker: TimePicker,
    selectDropdown: SelectDropDown,
    formList: FormList,
    slider: Slider,
    custom: Custom
};
