var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as intl from 'react-intl-universal';
import Upload from "antd/lib/upload";
import { PlusOutlined } from '@ant-design/icons';
import ImageManager from '@/common/components/ImageManager';
import FilePreview from '@/common/components/FilePreview';
import Icon from '@/components/Icon';
export default class UploadView extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isShow: false,
            fileList: []
        };
        this.updateFileList = (list) => {
            let fileList = [];
            if (typeof list === 'string' && list) {
                fileList = [
                    {
                        id: Math.random() * 100,
                        url: list
                    }
                ];
            }
            else if (Array.isArray(list)) {
                fileList = list.map((item, index) => (typeof item === 'string' ? {
                    id: index,
                    url: item
                } : item));
            }
            this.setState({
                fileList
            }, () => {
                fileList.length > 0 && this.onValueChange(fileList);
            });
        };
        this.chooseFile = (data) => {
            const fileList = [...this.state.fileList, ...data];
            this.setState({
                isShow: false,
                fileList
            }, () => {
                this.onValueChange(fileList);
            });
        };
        this.deleteFile = (index) => {
            const fileList = [...this.state.fileList];
            fileList.splice(index, 1);
            this.setState({
                fileList
            }, () => {
                this.onValueChange(fileList);
            });
        };
        this.onValueChange = (fileList) => {
            const { onChange } = this.props;
            onChange && onChange(fileList);
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { value: fileList = [] } = nextProps;
        if (JSON.stringify(fileList) !== JSON.stringify(this.state.fileList)) {
            this.updateFileList(fileList);
        }
    }
    componentDidMount() {
        const { value: fileList = [] } = this.props;
        this.updateFileList(fileList);
    }
    render() {
        const { isShow, fileList } = this.state;
        const _a = this.props, { isOnlyView = false, limit = { count: 1 }, disabled = false } = _a, props = __rest(_a, ["isOnlyView", "limit", "disabled"]);
        return (React.createElement("div", { className: 'field-upload' },
            React.createElement("div", { className: 'upload-list' },
                fileList.map((item, index) => React.createElement("div", { className: 'upload-list-item', key: item.id },
                    React.createElement(FilePreview, { src: item.url }),
                    !isOnlyView && !disabled ? React.createElement(Icon, { icon: 'delete', className: 'group-image-delete', onClick: () => this.deleteFile(index) }) : React.createElement(React.Fragment, null))),
                isOnlyView && !fileList.length ? React.createElement("span", null, intl.get('Global.NotUploaded')) : React.createElement(React.Fragment, null),
                !isOnlyView ? React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'upload-box', onClick: () => !disabled && this.setState({ isShow: true }) },
                        React.createElement(Upload, Object.assign({ disabled: disabled }, props, { fileList: fileList, listType: "picture-card", showUploadList: false, openFileDialogOnClick: false }), fileList.length >= limit.count ? null : React.createElement(PlusOutlined, { style: { fontSize: 16 } }))),
                    React.createElement(ImageManager, Object.assign({ isShow: isShow, callback: this.chooseFile, limit: limit, selectedCount: fileList.length }, props))) : React.createElement(React.Fragment, null))));
    }
}
UploadView.fieldName = 'upload';
UploadView.type = 'Upload';
