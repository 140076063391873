var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import Main from '@/pages/Main';
import Login from '@/pages/Login';
import Service from '@/pages/Service';
import { getCookie } from '@/utils/common/cookieUtil';
let Root = class Root extends React.Component {
    render() {
        const { islogin: loginFlag, mainMenuList } = this.props;
        let islogin = loginFlag;
        if (islogin && !getCookie('fastlogin') && !window.sessionStorage.getItem('sessionFlag')) {
            islogin = 0;
        }
        return islogin ? React.createElement(Main, { routes: mainMenuList }) : React.createElement(Login, null);
    }
};
Root = __decorate([
    Service()
], Root);
export default Root;
