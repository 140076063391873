import { get, post, postparam, put, del, upload } from '@/utils/http';
export default {
    getChannel: (data) => get('/setting/getChannel/', data),
    getAllChannel: (data) => get('/setting/getAllChannel', data),
    getEditChannel: (data) => get('/setting/changeChannel/', data),
    getChannelInfo: (data) => get('/setting/channelInfo/', data),
    getBasics: () => get('/setting/getBasics/'),
    getChannelBank: (data) => get('/setting/getChannelBank/', data),
    updateChannel: (data) => post('/setting/updateChannel/', data),
    deleteChannel: (data) => postparam('/setting/deleteChannel/', data),
    getChannelFunctionRevision: (data) => get('/setting/getChannelFunctionRevision/', data),
    getVirtualChannel: (data) => get('/setting/getVirtualChannel', data),
    getVirtualChannelInfo: (data) => get('/setting/getVirtualChannelInfo/', data),
    updateVirtualChannel: (data) => postparam('/setting/updateVirtualChannel/', data),
    deleteVirtualChannel: (data) => postparam('/setting/deleteVirtualChannel', data),
    getVirtualChannelList: (data) => get('/setting/getVirtualChannelList', data),
    getChannelList: () => get('/setting/getChannelList'),
    getVirtualChannelListInfo: (data) => get('/setting/getVirtualChannelListInfo/', data),
    updateVirtualChannelList: (data) => postparam('/setting/updateVirtualChannelList/', data),
    deleteVirtualChannelList: (data) => postparam('/setting/deleteVirtualChannelList/', data),
    getDefaultChannelInfo: (data) => get('/setting/getDefaultChannelInfo/', data),
    createTestOrder: (data) => post('/setting/createTestOrder', data),
    getTestOrderList: (data) => get('/setting/getTestOrderList', data),
    getTradeOrderChannel: (data) => get('/tradeorder/getTradeOrderChannel', data),
    getOrderChannel: (data) => get('/tradeorder/getOrderChannel', data),
    reCallCrm: (data) => get('/tradeorder/reCallCrm/' + data),
    saveBasics: (data) => postparam('/setting/saveBasics/', data),
    emailSendCode: (data) => postparam('/sms/emailSendCode', data),
    checkCode: (data) => postparam('/sms/checkCode', data),
    getPayKey: (data) => get('/setting/getLessPayKey/', data),
    getPasswordSetting: () => get('/setting/getPasswordSetting/'),
    setPasswordSetting: (data) => postparam('/setting/setPassword/', data),
    getCallbackAddr: () => get('/setting/getCallbackAddr'),
    setCallbackAddr: (data) => post('/setting/setCallbackAddr', data),
    getCountry: (data) => get('/deposit/getCountry', data),
    getChannelCurrency: (data) => get('/deposit/getChannelCurrency', data),
    getStaticCurrency: (data) => get('/deposit/getStaticCurrency', data),
    setDeSetting: (data) => post('/deposit/setDeSetting', data),
    getDeSetting: () => get('/deposit/getDeSetting'),
    getRateList: (data) => get('/rate/getRateList', data),
    getHourRate: (data) => get('/rate/getHourRate', data),
    updateRate: (data) => postparam('/rate/updateRate', data),
    getStaticAccountList: (data) => get('/static/getStaticAccountList', data),
    getStaticAccountinfo: (data) => post('/static/getStaticAccountinfo', data),
    setWireTransfer: (data) => post('/static/wireTransfer', data),
    setDigitalAccount: (data) => post('/static/digitalAccount', data),
    delStaticAccount: (data) => post('/static/moveAccount', data),
    changeDigitalStatusList: (data) => post('/static/changeDigitalStatusList', data),
    importDigital: (data) => upload('/static/excelImportDigital', data),
    downloadTemplate: (data) => get('/static/digitalExcelTemplate', Object.assign({ language: 'zh_cns' }, data)),
    exportDigital: (data) => post('/static/excelExportDigital', data),
    getProvinceList: (data) => postparam('/setting/getProvince', data),
    getCityList: (data) => postparam('/setting/getCity', data),
    getWithdrawalAccountList: (data) => get('/withdrawal/method', data),
    getWithdrawalAccount: (data) => get(`/withdrawal/method/${data}`),
    addWithdrawalAccount: (data) => post('/withdrawal/method', data),
    setWithdrawalAccount: (id, data) => put(`/withdrawal/method/${id}`, data),
    delWithdrawalAccount: (id) => del(`/withdrawal/method/${id}`),
    getPaymentType: (data) => get('/payment/getPaymentSetting', data),
    getPaymentTypeAll: (data) => get('/payment/getPaymentScope', data),
    getBankCodeForGritpay: () => get('/order/getAdapterDetails'),
};
