import * as intl from 'react-intl-universal';
class Rules {
    static get 'required.phone'() {
        return {
            validator: (rule, value, callback) => {
                const reg = value.code === '+86' ? /^1[34578]\d{9}$/ : /^\d{2,30}$/;
                if (value && value.code && reg.test(value.value)) {
                    callback();
                }
                else {
                    callback('phone format error');
                }
            },
            message: intl.get('Util.PhoneRules').d('手机号码格式有误')
        };
    }
    static get email() {
        return {
            validator: (rule, value, callback) => {
                if (!value) {
                    callback();
                    return;
                }
                const reg = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;
                if (!reg.test(value)) {
                    callback('email format error');
                }
                else {
                    callback();
                }
            },
            message: intl.get('Util.EmailRules').d('邮箱格式有误')
        };
    }
    static get required() {
        return {
            required: true,
            message: intl.get('Error.Required').d('此为必填项')
        };
    }
    static get percentage() {
        return {
            pattern: /^(100|0|0\.\d{1,2}|([1-9]\d?)(\.\d{1,2})?)$/,
            message: intl.get('Payment.InputPercentageTip').d('请输入0-100的数字（最多包含2位小数）')
        };
    }
    static get password() {
        return {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
            message: intl.get('Person.PWrules').d('密码长度8-16位，必须由数字和英文字母组成')
        };
    }
    static get 'required.array'() {
        return {
            type: 'array',
            required: true,
            message: intl.get('Error.Required').d('此为必填项')
        };
    }
    static get url() {
        return {
            pattern: /^(https?:\/\/)?([\w-]+\.)+[\w-]+(:\d+)?(\/[\w-.\/?%&=#]*)?$/,
            message: intl.get('Error.CorrectURL').d('此为必填项')
        };
    }
    static get limitSpaceAndSymbol() {
        return {
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/,
            message: intl.get('Error.NoSymbolSpace').d('不可输入空格以及符号')
        };
    }
    static get 'noSpaces'() {
        return {
            pattern: /^\S+$/,
            message: `不允许输入空格`
        };
    }
}
export default Rules;
