var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as moment from 'moment';
import TimePicker from "antd/lib/time-picker";
import StaticView from './StaticView';
export default class TimePickerField extends React.Component {
    constructor() {
        super(...arguments);
        this.adaptHandler = () => {
            const { value } = this.props;
            return (Array.isArray(value) ? value.filter(Boolean).join(' ~ ') : value) || '-';
        };
        this.changeHandler = (time, timeString) => {
            const { onChange, format = 'HH:mm:ss' } = this.props;
            onChange(timeString || (Array.isArray(time) ? time.map((item) => item.format(format)) : time.format(format)));
        };
        this.formatValueHandler = (value, format) => {
            if (!value) {
                return null;
            }
            const splitArr = value.split(' ').filter(Boolean);
            const newValue = splitArr.length > 1 ? splitArr[splitArr.length - 1] : value;
            return moment(newValue, format);
        };
    }
    render() {
        const _a = this.props, { pickerType = 'single', disabled, value, size = 'large', format = 'HH:mm:ss', staticView } = _a, props = __rest(_a, ["pickerType", "disabled", "value", "size", "format", "staticView"]);
        const remainingProps = Object.assign({}, props);
        const isRange = pickerType === 'range';
        const PickerContainer = isRange ? TimePicker.RangePicker : TimePicker;
        const formatValue = Array.isArray(value) ? value.map((val) => this.formatValueHandler(val, format)) : this.formatValueHandler(value, format);
        if (remainingProps.placeholder === undefined) {
            delete remainingProps.placeholder;
        }
        return ((staticView && disabled) ? React.createElement(StaticView, { display: this.adaptHandler() }) : React.createElement(PickerContainer, Object.assign({}, remainingProps, {
            defaultValue: formatValue,
            value: formatValue
        }, { size: size, format: format, onChange: this.changeHandler })));
    }
}
TimePickerField.fieldName = 'timePicker';
