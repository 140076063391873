export const whiteList = [
    '/user/checkStatus'
];
export default {
    429: 'Error.TooManyRequests',
    1001: 'Error.UnknownError',
    1002: 'Error.UnknownError',
    1003: 'Error.ParamsError',
    1004: 'Error.UnknownError',
    1005: 'Error.RequestHandling',
    1006: 'Error.UnknownError',
    2000: 'Error.UserNotLogin',
    2001: 'Error.UserNotExist',
    2002: 'Error.UserNoPermission',
    2003: 'Error.UserLoginError',
    2004: 'Error.LockedAccount',
    2005: 'Error.UserInfoError',
    2006: 'Error.HasAccountExist',
    2007: 'Error.UserSettingNotExist',
    2008: 'Error.UserDisabled',
    2013: 'Error.UserLogout',
    2009: 'Error.PasswordExpired',
    2010: 'Error.RepeatLogin',
    2011: 'Error.UserHasBeenDeleted',
    2012: 'Error.AccountUniqueExisted',
    2100: 'Error.WorkOrderNotExist',
    2101: 'Error.AssignerNotPermission',
    2102: 'Error.WorkOrderStatusChange',
    2200: 'Error.NoticeNotExist',
    2201: 'Error.NoticeTypeNotExist',
    2202: 'Error.BatchDeleteNoticeFailed',
    2300: 'Error.UserAuditNotExist',
    2301: 'Error.UserAuditStatusError',
    2302: 'Error.UserAuditDataError',
    2303: 'Error.UserAuditDataDuplicate',
    2304: 'Error.AuditInfoIncomplete',
    2400: 'Error.UserRoleDuplicate',
    2500: 'Error.InviteLinkError',
    2501: 'Error.InviteLinkExist',
    2502: 'Error.UpperAgentError',
    2503: 'Error.UpperSalesError',
    2504: 'Error.AccountExist',
    2505: 'Error.InviteCodeError',
    2510: 'Error.ReferralCodeError',
    2511: 'Error.ReferralCodeLimit',
    2512: 'Error.ReferralCodeNotExist',
    2513: 'Error.LinkGroupAssignRepeat',
    2514: 'Error.ReferralCodeIsUsed',
    2515: 'Error.ReferralExisted',
    2600: 'Error.SubClientNotExist',
    2601: 'Error.UserRelationError',
    2701: 'Error.UserTypeError',
    2800: 'Error.LoginStatusChange',
    2801: 'Error.LoginStatusRemote',
    2802: 'Error.LoginStatusChange',
    2803: 'Error.PasswordChange',
    3000: 'Error.CreateFundOrderFailed',
    3001: 'Error.AmountLimitError',
    3002: 'Error.FeeLimitError',
    3003: 'Error.DepositOrderNotExist',
    3004: 'Error.DepositAmountError',
    3005: 'Error.WithdrawalAmountError',
    3006: 'Error.TransferMethodError',
    3007: 'Error.TransferMtAccountNotExist',
    3008: 'Error.TransferAmountError',
    3009: 'Error.TransferAmountLimit',
    3010: 'Error.WithdrawalConditionError',
    3011: 'Error.WithdrawalAuditExist',
    3012: 'Error.WithdrawalLastTimeExist',
    3013: 'Error.DiffNameTransferRelationError',
    3014: 'Error.WithdrawalReCallTimeout',
    3015: 'Error.GreaterThanWithDrawalAmount',
    3100: 'Error.ChannelError',
    3101: 'Error.BankInfoError',
    3200: 'Error.TelAccountNotExist',
    3201: 'Error.DigitalAccountNotExist',
    3300: 'Error.ExchangeRateError',
    3301: 'Error.GetExchangeRateError',
    3400: 'Error.OrderAuditStatusError',
    3401: 'Error.OrderAuditProcessing',
    3402: 'Error.OrderStatusError',
    3500: 'Error.WalletInfoError',
    3501: 'Error.WalletCurrencyError',
    4000: 'Error.UserMarginNotEnough',
    4001: 'Error.MtAccountNotExist',
    4002: 'Error.PasswordFormatError',
    4003: 'Error.OpenMtAccountFail',
    4004: 'Error.BalanceNotZeroCantDelete',
    4005: 'Error.UpdateMtAccountFail',
    4006: 'Error.DeleteMtAccountFail',
    4007: 'Error.MtAccountInfoError',
    4100: 'Error.TradeAccountTypeNotExist',
    4101: 'Error.MtTradeAccountExistAlias',
    4102: 'Error.MtGroupCurrencyNotMatching',
    4200: 'Error.AccountTypeExist',
    4201: 'Error.MtAccountTypeUsed',
    4202: 'Error.MtAccountLoginUsed',
    4203: 'Error.MtAccountTypeNameUsed',
    4204: 'Error.MtAccountTypeGroupUsed',
    4205: 'Error.MtAccountTypeUsedByLink',
    4300: 'Error.AccountAliasNotExist',
    4301: 'Error.AccountGroupNotExist',
    4302: 'Error.MtAliasRepeat',
    4303: 'Error.MtAliasDeleteError',
    4304: 'Error.MtAliasUsedByAccountType',
    4305: 'Error.MtAliasUsedByLink',
    4306: 'Error.MtAliasUsedCannotDel',
    4400: 'Error.MtLoginSettingError',
    4401: 'Error.MtLoginRepeat',
    4402: 'Error.MtAccountLoginRangeUsed',
    4500: 'Error.MtAccountOpenNotExist',
    4501: 'Error.MtAccountOpenExistAlias',
    4600: 'Error.MtTradeAuditNotExist',
    4601: 'Error.MtOpenAccountAuditExist',
    4602: 'Error.MtLeverageAuditExist',
    4700: 'Error.MtAssignableServer',
    4800: 'Error.MtRequestParamsError',
    4801: 'Error.MtServerNotInstalled',
    4802: 'Error.MtRequestHandleError',
    4803: 'Error.MtConnectError',
    4804: 'Error.MtServerInfoError',
    4805: 'Error.MtPasswordError',
    4806: 'Error.MtServerStatusError',
    4900: 'Error.TradeAmountInvalid',
    4901: 'Error.GetMtAccountFundInfoError',
    4902: 'Error.MtAccountCreditNotEnough',
    5000: 'Error.FileNotExist',
    5001: 'Error.FileFormatError',
    5100: 'Error.ValidatorCodeError',
    5101: 'Error.ValidatorCodeInvalid',
    5102: 'Error.ValidatorCodeExpire',
    5103: 'Error.SendValidatorCodeError',
    5200: 'Error.CaptchaCheckError',
    5301: 'Error.SendSmsError',
    5302: 'Error.EmailNotFound',
    6000: 'Error.PlatformSettingError',
    6001: 'Error.SocialDomainError',
    6002: 'Error.ClientDomainError',
    6100: 'Error.ExportTaskNotExist',
    6101: 'Error.ExportParamsError',
    7000: 'Error.BaseCurrencyRepeat',
    7001: 'Error.CurrencyGroupNameRepeat',
    7002: 'Error.RebateGroupAccountRepeat',
    7003: 'Error.UpperAgentRebateStrategyError',
    7004: 'Error.AgentRebateStrategyError',
    7005: 'Error.UpperSalesRebateStrategyError',
    7006: 'Error.SalesRebateStrategyError',
    7007: 'Error.TradeSymbolRepeat',
    7008: 'Error.SubAgentHasRebateStrategy',
    7009: 'Error.RebateGroupNameRepeat',
    7100: 'Error.RebateAuditNotExist',
    7200: 'Error.RebateTplHasBeenUsed',
    7300: 'Error.RebateRuleRepeat',
    7301: 'Error.RuleNameRepeat',
    7400: 'Error.RebateGroupExistUser',
    7501: 'Error.RebateGroupNotExist',
    7502: 'Error.RebateTplNotExist',
    7601: 'Error.RulesLevelLimit',
    8000: 'Error.SalesTeamNotExist',
    8001: 'Error.TeamNameExist',
    8002: 'Error.TeamRecordNotExist',
    8101: 'Error.ClueEmailExist',
    8102: 'Error.CluePhoneExist',
    8103: 'Error.ClueNotExist',
    8104: 'Error.ClueStatusError',
    8105: 'Error.ClueSearchMore',
    9000: 'Error.DataDuplicateException',
    2020: 'Error.ImgCodeVerifyError',
    2021: 'Error.ImgCodeVerifyExpireError'
};
