import * as React from 'react';
import * as intl from 'react-intl-universal';
import Modal from "antd/lib/modal";
import Alert from "antd/lib/alert";
import Button from "antd/lib/button";
export default class Dialog extends React.Component {
    constructor() {
        super(...arguments);
        this.closeModal = () => {
            const { id, onTrigger } = this.props;
            onTrigger('closeModal', id);
        };
        this.closeClickHandler = () => {
            const { onCancel } = this.props.config;
            onCancel && onCancel();
            this.closeModal();
        };
        this.okClickHandler = () => {
            const { onOk, auto = '0' } = this.props.config;
            onOk && onOk();
            auto == '0' && this.closeModal();
        };
        this.getFooter = (footer, okText) => {
            switch (footer) {
                case 'close':
                    return (React.createElement("p", { style: { textAlign: 'right' } },
                        React.createElement(Button, { type: 'primary', size: 'large', onClick: this.closeClickHandler }, intl.get('Global.Cancel'))));
                case 'all':
                    return (React.createElement("div", null,
                        React.createElement(Button, { size: 'large', onClick: this.closeClickHandler }, intl.get('Global.Cancel')),
                        React.createElement(Button, { type: 'primary', size: 'large', onClick: this.okClickHandler }, okText || intl.get('Global.Confirm'))));
                case 'submit':
                    return (React.createElement("div", null,
                        React.createElement(Button, { type: 'primary', size: 'large', onClick: this.okClickHandler }, okText || intl.get('Global.Confirm'))));
                default:
                    return footer;
            }
        };
    }
    render() {
        const { config } = this.props;
        const { title, okText, cancelText, locker, content, style, width, alert, wrapClassName, footer } = config;
        return (React.createElement(Modal, Object.assign({}, config, { title: title, onOk: this.okClickHandler, onCancel: this.closeClickHandler, confirmLoading: locker, style: style, width: width, wrapClassName: wrapClassName, okText: okText, footer: this.getFooter(footer, okText), cancelText: cancelText, visible: true, maskClosable: false }),
            alert && React.createElement(Alert, { message: alert, showIcon: true }),
            content));
    }
}
