import * as React from 'react';
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Form from "antd/lib/form";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import FormBuilderField from './FormBuilderField';
import asyncRuleFactory from './utils/ruleFactory';
import { segment } from './utils';
import * as intl from 'react-intl-universal';
const FormItem = Form.Item;
let debounceTimer;
let changeValues = {};
function FormBuilder(props) {
    const [form] = Form.useForm();
    const { columns = 1, extension } = props;
    const meta = segment(props.meta.filter((item) => item), columns);
    if (extension)
        extension(form);
    return React.createElement(FormBuilderInner, Object.assign({}, props, { form: form, meta: meta }));
}
function onFinish() {
}
function valuesChangeDebounce(fn, delay) {
    return (...rest) => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
            changeValues = Object.assign(Object.assign({}, changeValues), rest[0]);
        }
        debounceTimer = setTimeout(() => {
            rest[0] = Object.assign(Object.assign({}, changeValues), rest[0]);
            fn(...rest);
            changeValues = {};
        }, delay);
    };
}
function onValuesChange(debounceFn, changedValues, allValues) {
    debounceFn(changedValues, allValues);
}
function RenderFields(props) {
    const { meta } = props;
    return meta.map((fields, rowIndex) => (React.createElement(Row, { key: `form-row-${rowIndex}` },
        React.createElement(RenderField, Object.assign({}, props, { fields: fields, rowIndex: rowIndex })))));
}
function RenderField(props) {
    const { fields, columns = 1, rowIndex } = props;
    return fields.map((field, colIndex) => {
        const span = Math.floor(24 / (field.exclusiveLine ? 1 : (fields.length > columns ? fields.length : field.columns || columns)));
        let { label, validators = [], key, defaultValue, hidden } = field;
        if (hidden) {
            return (React.createElement(Col, { key: `form-col-${rowIndex}-${colIndex}`, span: span, style: { display: 'none' } },
                React.createElement(FormBuilderField, Object.assign({}, props, field, { label: label }))));
        }
        if (label) {
            const className = !!validators && (validators.join('.').indexOf('required') > -1 || validators.find((item) => item.required)) ? 'form-item-required' : null;
            label = React.createElement("span", { title: label, className: className }, label);
        }
        const rules = getRules(props, validators, key, defaultValue);
        return (React.createElement(Col, { key: `form-col-${rowIndex}-${colIndex}`, span: span },
            React.createElement(FormBuilderField, Object.assign({}, props, field, { rules: rules, label: label }))));
    });
}
function getRules(props, validators, key, defaultValue) {
    const { form, rulesList = {} } = props;
    return validators ? validators.filter((i) => i).map((item) => {
        if (Array.isArray(item)) {
            const [ruleName, ruleParams, callback] = item;
            const rule = rulesList[ruleName];
            return typeof rule === 'function'
                ? rule(ruleName)
                : asyncRuleFactory(rule, Object.assign({ defaultValue }, ruleParams), callback);
        }
        else if (typeof (item) === 'object') {
            const { validator, message } = item;
            return {
                validator: (r, value, callback) => {
                    validator(r, value, callback, { form, key });
                },
                message
            };
        }
        else {
            const rule = rulesList[item];
            if (rule) {
                const { validator, message } = rule;
                return Object.assign(Object.assign({}, rule), { validator: validator
                        ? (r, value, callback) => {
                            validator(r, value, callback, { form, key });
                        }
                        : validator, message });
            }
        }
    }).filter((item) => !!item) : [];
}
const buttonHandler = (props) => (e) => {
    const { form, callback } = props;
    callback && callback(form, e);
};
function RenderButton(props) {
    let { buttons = [], isValid, mfaValue, mfaError, handleMfaChange } = props;
    if (!buttons.length)
        return null;
    buttons = buttons.filter((item) => item);
    const isBlock = buttons.some((item) => {
        const { attributes = { block: false } } = item;
        return attributes.block;
    });
    return (React.createElement(FormItem, { className: isBlock ? 'block-button-group' : 'inline-button-group' },
        isValid && (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '20px' } },
            React.createElement(Input, { key: "mfa-input", value: mfaValue, onChange: handleMfaChange, placeholder: intl.get('Util.Enter2FAPlaceholder'), style: { width: '200px' } }),
            mfaError && (React.createElement("div", { style: { color: 'red', marginTop: '5px' } }, mfaError)))),
        buttons.map((item, index) => {
            const { text, disabled = false, attributes = {} } = item;
            attributes.className = `${attributes.className || ''} height36`;
            return (React.createElement(Button, Object.assign({ key: `form-button-${index}`, disabled: disabled }, attributes, { onClick: buttonHandler(Object.assign(Object.assign({}, props), item)) }), text));
        })));
}
function FormBuilderInner(props) {
    const { form, initialValues = {}, onValuesChange: onChange = () => undefined, delay } = props;
    const { isValid, mfaValue, mfaError, handleMfaChange } = props;
    const debounceFn = valuesChangeDebounce(onChange, delay || 150);
    return (React.createElement(Form, { form: form, className: 'control-hooks wireframe', colon: false, initialValues: initialValues, onFinish: onFinish, onValuesChange: (changedValues, allValues) => onValuesChange(debounceFn, changedValues, allValues) },
        React.createElement(RenderFields, Object.assign({}, props)),
        React.createElement(RenderButton, Object.assign({}, props, { isValid: isValid, mfaValue: mfaValue, mfaError: mfaError, handleMfaChange: handleMfaChange }))));
}
export default FormBuilder;
