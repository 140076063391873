export class Utils {
    constructor() {
        this.unique = 0;
        this.endsWith = (str, suffix) => str.indexOf(suffix, str.length - suffix.length) !== -1;
        this.getFileTypes = (keys) => keys.map((key) => Utils.fileType[key]).join(',');
        this.uuid = (prefix) => {
            const date = new Date();
            const time = date.getTime();
            const random = Math.floor(Math.random() * 1000000000);
            this.unique++;
            return `${prefix}_${random}${this.unique}${String(time)}`;
        };
        this.isAcceptable = (file, acceptedFiles) => {
            if (file && acceptedFiles) {
                const acceptedFilesArray = Array.isArray(acceptedFiles)
                    ? acceptedFiles
                    : acceptedFiles.split(',');
                const fileName = file.name || '';
                const mimeType = file.type || '';
                const baseMimeType = mimeType.replace(/\/.*$/, '');
                return acceptedFilesArray.some((type) => {
                    const validType = Utils.fileType[type.trim()];
                    if (validType.charAt(0) === '.') {
                        return this.endsWith(fileName.toLowerCase(), validType.toLowerCase());
                    }
                    else if (/\/\*$/.test(validType)) {
                        return baseMimeType === validType.replace(/\/.*$/, '');
                    }
                    return mimeType === validType;
                });
            }
            return true;
        };
        this.isTextarea = (element) => (element !== null && element.tagName.toLowerCase() === 'textarea');
        this.isFunction = (x) => typeof x === 'function';
        this.isValidKey = (keys) => (key) => keys.indexOf(key) !== -1;
        this.bindHandlers = (props, editor, initEvent) => {
            Object.keys(props)
                .filter(this.isValidKey(Utils.eventsType))
                .forEach((key) => {
                const handler = props[key];
                if (this.isFunction(handler)) {
                    if (key === 'onInit') {
                        handler(initEvent, editor);
                    }
                    else {
                        editor.on(key.substring(2), (e) => handler(e, editor));
                    }
                }
            });
        };
        this.normalizePluginArray = (plugins) => {
            if (typeof plugins === 'undefined' || plugins === '') {
                return [];
            }
            return Array.isArray(plugins) ? plugins : plugins.split(' ');
        };
        this.mergePlugins = (initPlugins, inputPlugins) => (this.normalizePluginArray(initPlugins).concat(this.normalizePluginArray(inputPlugins)));
    }
}
Utils.eventsType = [
    'onActivate',
    'onAddUndo',
    'onBeforeAddUndo',
    'onBeforeExecCommand',
    'onBeforeGetContent',
    'onBeforeRenderUI',
    'onBeforeSetContent',
    'onBeforePaste',
    'onBlur',
    'onChange',
    'onClearUndos',
    'onClick',
    'onContextMenu',
    'onCopy',
    'onCut',
    'onDblclick',
    'onDeactivate',
    'onDirty',
    'onDrag',
    'onDragDrop',
    'onDragEnd',
    'onDragGesture',
    'onDragOver',
    'onDrop',
    'onExecCommand',
    'onFocus',
    'onFocusIn',
    'onFocusOut',
    'onGetContent',
    'onHide',
    'onInit',
    'onKeyDown',
    'onKeyPress',
    'onKeyUp',
    'onLoadContent',
    'onMouseDown',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseOut',
    'onMouseOver',
    'onMouseUp',
    'onNodeChange',
    'onObjectResizeStart',
    'onObjectResized',
    'onObjectSelected',
    'onPaste',
    'onPostProcess',
    'onPostRender',
    'onPreProcess',
    'onProgressState',
    'onRedo',
    'onRemove',
    'onReset',
    'onSaveContent',
    'onSelectionChange',
    'onSetAttrib',
    'onSetContent',
    'onShow',
    'onSubmit',
    'onUndo',
    'onVisualAid'
];
Utils.fileType = {
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    txt: 'text/plain'
};
export const getGlobal = () => (typeof window !== 'undefined' ? window : global);
export const getTinymce = () => {
    const global = getGlobal();
    return global && global.tinymce ? global.tinymce : null;
};
export default new Utils();
