import * as React from 'react';
import * as intl from 'react-intl-universal';
import { common } from '@/api';
import message from "antd/lib/message";
import i18n from '@/common/I18n';
export default class Captcha extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            initing: true,
            isVerified: this.props.value
        };
        this.init = () => {
            const { initGeetest } = window;
            const language = i18n.getDefault().toLowerCase();
            const geetestCatch = document.getElementById('geetest-captcha');
            this.setState({
                initing: true
            }, () => {
                common.geeTestInit().then((data) => {
                    initGeetest && initGeetest({
                        gt: data.gt,
                        lang: language,
                        challenge: data.challenge,
                        width: '100%',
                        product: 'float',
                        offline: !data.success,
                        protocol: 'https://'
                    }, (captchaObj) => {
                        this.setState({
                            initing: false
                        }, () => {
                            captchaObj.appendTo(geetestCatch);
                            captchaObj.onSuccess(() => {
                                const result = captchaObj.getValidate();
                                common.geeTestCheck(result).then((res) => {
                                    if (res.ret_code === 0) {
                                        this.resultChangeHandler(true);
                                        captchaObj.destroy();
                                        message.success(intl.get('Util.ValidateSuccess'));
                                    }
                                    else {
                                        this.resultChangeHandler(false);
                                        captchaObj.reset();
                                        message.error(intl.get('Util.ValidateError'));
                                    }
                                });
                            });
                            captchaObj.onError(() => {
                                message.error(intl.get('Util.ValidateError'));
                            });
                        });
                    });
                });
            });
        };
        this.resultChangeHandler = (result) => {
            const { onChange } = this.props;
            onChange && onChange(result);
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.isVerified) {
            this.setState({
                isVerified: nextProps.value
            });
        }
    }
    componentDidMount() {
        const { isVerified } = this.state;
        !isVerified && this.init();
    }
    render() {
        const { isVerified } = this.state;
        return !isVerified ? React.createElement("div", { id: 'geetest-captcha' }, this.state.initing && intl.get('Captcha.Setting')) : null;
    }
}
Captcha.fieldName = 'captcha';
Captcha.type = 'Captcha';
