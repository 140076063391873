export default function bind(target, event, callback) {
    if (target.addEventListener) {
        target.addEventListener(event, callback, false);
    }
    else {
        target.attachEvent(`on${event}`, function () {
            callback.call(target);
        });
    }
}
