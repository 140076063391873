export const PAYOUT_ORDER_STATUS = {
    '0': "Global.Draft",
    '1': "Global.payout_processing_order_status",
    '2': "Global.Done",
    '3': "Order.Canceled",
    '4': "Trade.Fail",
    '5': "Order.CallbackExternalSystemFailed"
};
export const DIGITAL_CURRENCY_CHANNEL_STATUS = {
    '0': "Global.Disable",
    '1': "Global.Enable",
};
export const CHANNEL_ROUNDING_PLACES_TO_TEXT = {
    '-2': '_HUNDREDS',
    '-1': '_TENS',
    '0': '_ONES',
    '1': '_TENTHS',
    '2': '_HUNDREDTHS',
    '3': '_THOUSANDTHS',
    '4': '_TEN_THOUSANDTHS',
    '5': '_HUNDRED_THOUSANDTHS',
    '6': '_MILLIONTHS',
};
export const CHANNEL_ROUNDING_SAMPLE = {
    'ROUND_UP_HUNDREDS': "12345.123456 = 12400",
    'ROUND_UP_TENS': "12345.123456 = 12350",
    'ROUND_UP_ONES': "12345.123456 = 12346",
    'ROUND_UP_TENTHS': "12345.123456 = 12345.2",
    'ROUND_UP_HUNDREDTHS': "12345.123456 = 12345.13",
    'ROUND_UP_THOUSANDTHS': "12345.123456 = 12345.124",
    'ROUND_UP_TEN_THOUSANDTHS': "12345.123456 = 12345.1235",
    'ROUND_UP_HUNDRED_THOUSANDTHS': "12345.123456 = 12345.12346",
    'ROUND_UP_MILLIONTHS': "12345.1234567 = 12345.123457",
    'ROUND_DOWN_HUNDREDS': "12345.123456 = 12300",
    'ROUND_DOWN_TENS': "12345.123456 = 12340",
    'ROUND_DOWN_ONES': "12345.123456 = 12345",
    'ROUND_DOWN_TENTHS': "12345.123456 = 12345.1",
    'ROUND_DOWN_HUNDREDTHS': "12345.123456 = 12345.12",
    'ROUND_DOWN_THOUSANDTHS': "12345.123456 = 12345.123",
    'ROUND_DOWN_TEN_THOUSANDTHS': "12345.123456 = 12345.1234",
    'ROUND_DOWN_HUNDRED_THOUSANDTHS': "12345.123456 = 12345.12345",
    'ROUND_DOWN_MILLIONTHS': "12345.1234567 = 12345.123456",
    'ROUND_HALF_UP_HUNDREDS': "12345.123456 = 12300",
    'ROUND_HALF_UP_TENS': "12345.123456 = 12350",
    'ROUND_HALF_UP_ONES': "12345.123456 = 12345",
    'ROUND_HALF_UP_TENTHS': "12345.123456 = 12345.1",
    'ROUND_HALF_UP_HUNDREDTHS': "12345.123456 = 12345.12",
    'ROUND_HALF_UP_THOUSANDTHS': "12345.123456 = 12345.123",
    'ROUND_HALF_UP_TEN_THOUSANDTHS': "12345.123456 = 12345.1235",
    'ROUND_HALF_UP_HUNDRED_THOUSANDTHS': "12345.123456 = 12345.12346",
    'ROUND_HALF_UP_MILLIONTHS': "12345.1234567 = 12345.123457",
};
