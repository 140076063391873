const Base64 = {
    convertToBlob: (data, type) => {
        const decodedData = window.atob(data.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(decodedData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < decodedData.length; i++) {
            uint8Array[i] = decodedData.charCodeAt(i);
        }
        return new Blob([arrayBuffer], { type });
    }
};
export { Base64 };
