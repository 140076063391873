import * as moment from 'moment';
export class TimeUtil {
    constructor() {
        this.config = {
            timezone: 0,
            timeformat: 'YYYY/MM/DD HH:mm:ss'
        };
        this.set = (config) => {
            this.config = config;
        };
        this.format = (value) => {
            const { timeformat, timezone } = this.config;
            return value ? moment(value).add(timezone, 'h').format(timeformat) : value;
        };
        this.formatUTC = (value) => {
            const { timeformat, timezone } = this.config;
            return value ? moment(value).subtract(timezone, 'h').format(timeformat) : value;
        };
        this.getStartTime = (value) => {
            const { timeformat, timezone } = this.config;
            return value ? moment(value).startOf('day').subtract(timezone, 'h').format(timeformat) : value;
        };
        this.getEndTime = (value) => {
            const { timeformat, timezone } = this.config;
            return value ? moment(value).endOf('day').subtract(timezone, 'h').format(timeformat) : value;
        };
    }
    get timezone() {
        return this.config.timezone;
    }
    get timeformat() {
        return this.config.timeformat;
    }
    get utcTime() {
        return moment.utc().format(this.config.timeformat || 'YYYY-MM-DD HH:mm:ss');
    }
    get moment() {
        return moment;
    }
}
export default new TimeUtil();
