var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import InputNumber from "antd/lib/input-number";
import RangePicker from './RangePicker';
import ASelect from '@/components/AntdCustomComponent/ASelect';
const InputGroup = Input.Group;
const { Option } = Select;
const { TextArea } = Input;
export default class FilterBar extends React.Component {
    constructor() {
        super(...arguments);
        this.defaultValue = this.props.resetInitValue || this.props.searchParams;
        this.state = this.props.searchParams;
        this.handleDate = (start, end, callback) => (value) => {
            this.setState({ [start]: value[0], [end]: value[1] }, () => callback && callback(value[0], value[1]));
        };
        this.handleSelect = (name, callback) => (value) => {
            this.setState({ [name]: value });
            if (callback) {
                callback({ [name]: value }, this.setFieldsValue);
            }
        };
        this.handleInput = (event) => {
            this.setState({ [event.target.name]: event.target.value });
        };
        this.handleInputNumber = (val, name) => {
            this.setState({ [name]: val });
        };
        this.handleSubmit = () => {
            const { onSearchChange } = this.props;
            onSearchChange && onSearchChange(this.state);
        };
        this.handleReset = () => {
            const { onSearchChange } = this.props;
            this.setState(this.defaultValue);
            console.log("this.defaultValue", this.defaultValue);
            onSearchChange && onSearchChange(this.defaultValue);
        };
        this.setFieldsValue = (value) => {
            this.setState(value);
        };
        this.reinitialize = (value) => {
            this.setState(value);
            this.defaultValue = value;
        };
        this.elementGenerator = (conf, index) => {
            const { disabledAll } = this.props;
            conf.disabled = disabledAll || conf.disabled;
            const getSelectValue = (value) => {
                const emptyStringValue = conf.options.find((item) => item.value === '');
                return value ? value : emptyStringValue && value === '' ? '' : undefined;
            };
            let selectValue = undefined;
            switch (conf.type) {
                case 'dateSelect':
                    return (React.createElement(RangePicker, Object.assign({ className: "filter-item" }, conf, { defaultDate: this.state[conf.start] && this.state[conf.end] ? [this.state[conf.start], this.state[conf.end]] : '', onDateChange: this.handleDate(conf.start, conf.end, conf === null || conf === void 0 ? void 0 : conf.callback), disabledDate: conf.disabledDate, lang: conf.lang, showTime: conf.showTime, disabledRanges: conf.disabledRanges, disabled: conf.disabled, key: index })));
                case 'select':
                    selectValue = getSelectValue(this.state[conf.key]);
                    const { callback } = conf, rest = __rest(conf, ["callback"]);
                    return (React.createElement(ASelect, Object.assign({ className: "filter-item", style: { width: 150 } }, rest, { placeholder: conf.placeholder, onChange: this.handleSelect(conf.key, callback), key: index, value: selectValue, disabled: conf.disabled }), conf.options.map((opt) => (React.createElement(Option, { value: opt.value, title: opt.label, key: opt.value, disabled: opt.disabled }, opt.label)))));
                case 'input':
                    return (React.createElement(Input, Object.assign({ className: "filter-item", style: { width: 150 }, placeholder: conf.placeholder, maxLength: conf.maxLength || 60 }, conf, { name: conf.key, value: this.state[conf.key], disabled: conf.disabled, onPressEnter: this.handleSubmit, onChange: (val) => {
                            if (conf.onChange) {
                                conf.onChange(val);
                            }
                            this.handleInput(val);
                        }, key: index })));
                case 'inputNumber':
                    return (React.createElement(InputNumber, Object.assign({ className: "filter-item", style: { width: 150 }, placeholder: conf.placeholder }, conf, { name: conf.key, value: this.state[conf.key], disabled: conf.disabled, onChange: (val) => this.handleInputNumber(val, conf.key), key: index })));
                case 'textArea':
                    return (React.createElement(TextArea, Object.assign({ className: "filter-item", rows: conf.rows, placeholder: conf.placeholder, style: { width: 150 }, name: conf.key, value: this.state[conf.key], disabled: conf.disabled, maxLength: conf.maxLength || 200, onChange: this.handleInput, key: index }, conf)));
                case 'selectInput':
                    return (React.createElement(InputGroup, { className: "filter-item", compact: true, style: { display: 'inline-block', width: 350 }, key: index },
                        React.createElement(ASelect, { className: "search-select", style: { width: 120, marginRight: 0 }, onChange: this.handleSelect(conf.selectKey, conf.callback), value: this.state[conf.selectKey], disabled: conf.disabled }, conf.options.map((opt) => (React.createElement(Option, { value: opt.value, key: opt.value, title: opt.label, disabled: opt.disabled }, opt.label)))),
                        React.createElement(Input, { placeholder: conf.placeholder, style: { width: 230, marginLeft: -1 }, name: conf.inputKey, maxLength: 50, value: this.state[conf.inputKey], disabled: conf.disabled, onPressEnter: this.handleSubmit, onChange: this.handleInput })));
                case 'submit':
                case 'reset':
                case 'btn':
                    const isSubmit = conf.type === 'submit';
                    const isReset = conf.type === 'reset';
                    return (React.createElement(Button, { type: conf.btnType || (isSubmit ? 'primary' : 'default'), key: index, danger: conf.danger, ghost: conf.ghost, icon: conf.icon, onClick: isSubmit ? this.handleSubmit : isReset ? this.handleReset : conf.callback, disabled: conf.disabled, className: "filter-item", style: conf.style }, conf.text));
                case 'custom':
                    const element = typeof conf.render === 'function' ? conf.render() : conf.render;
                    return (React.createElement("div", { className: "filter-item", style: { display: 'inline-block', maxWidth: 350 }, key: index }, React.cloneElement(element, { disabled: disabledAll })));
                default:
                    return null;
            }
        };
    }
    componentDidMount() {
        const { extension } = this.props;
        if (extension) {
            extension({
                setFieldsValue: this.setFieldsValue,
                setReset: this.handleReset,
                reinitialize: this.reinitialize
            });
        }
    }
    render() {
        const { left = [], right = [] } = this.props.filterConfigs;
        return (React.createElement("div", { className: "filter-bar" },
            left.length ? (React.createElement("div", { className: "filter-bar-left" }, left.filter((item) => item).map((item, index) => this.elementGenerator(item, `bar-left-${index}`)))) : (React.createElement("div", null)),
            right.length ? (React.createElement("div", { className: "filter-bar-right" }, right.filter((item) => item).map((item, index) => this.elementGenerator(item, `bar-right-${index}`)))) : null));
    }
}
