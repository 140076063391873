import * as React from 'react';
import * as ScriptLoader from './loader';
import Utils, { getTinymce } from './utils';
const scriptState = ScriptLoader.create();
export default class Editor extends React.Component {
    constructor() {
        super(...arguments);
        this.element = null;
        this.initialise = () => {
            const finalInit = Object.assign(Object.assign({}, this.props.init), { target: this.element, inline: this.inline, plugins: Utils.mergePlugins(this.props.init && this.props.init.plugins, this.props.plugins), toolbar: this.props.toolbar || (this.props.init && this.props.init.toolbar), setup: (editor) => {
                    this.editor = editor;
                    editor.on('init', (e) => {
                        this.initEditor(e, editor);
                    });
                    if (this.props.init && typeof this.props.init.setup === 'function') {
                        this.props.init.setup(editor);
                    }
                } });
            if (Utils.isTextarea(this.element)) {
                this.element.style.visibility = '';
            }
            getTinymce().init(finalInit);
        };
    }
    UNSAFE_componentWillMount() {
        this.id = this.id || this.props.id || Utils.uuid('fzzf-react');
        this.inline = this.props.inline ? this.props.inline : this.props.init && this.props.init.inline;
    }
    componentDidMount() {
        if (getTinymce() !== null) {
            this.initialise();
        }
        else if (this.element) {
            const doc = this.element.ownerDocument;
            ScriptLoader.load(scriptState, doc, window.config.js_file('/tinymce/tinymce.min.js'), this.initialise);
        }
    }
    componentWillUnmount() {
        if (getTinymce() !== null) {
            getTinymce().remove(this.editor);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.editor && this.editor.initialized) {
            this.currentContent = this.currentContent || this.editor.getContent();
            if (typeof nextProps.value === 'string' && nextProps.value !== this.props.value && nextProps.value !== this.currentContent) {
                this.editor.setContent(nextProps.value);
            }
        }
    }
    render() {
        return this.inline ? this.renderInline() : this.renderIframe();
    }
    initEditor(initEvent, editor) {
        const value = typeof this.props.value === 'string'
            ? this.props.value
            : typeof this.props.initialValue === 'string' ? this.props.initialValue : '';
        editor.setContent(value);
        if (Utils.isFunction(this.props.onEditorChange)) {
            editor.on('blur', () => {
                this.props.onFloating(true);
            });
            editor.on('focus', () => {
                this.props.onFloating(false);
            });
            editor.on('change keyup setcontent', () => {
                this.currentContent = editor.getContent();
                if (Utils.isFunction(this.props.onEditorChange)) {
                    this.props.onEditorChange(this.currentContent);
                }
            });
        }
        Utils.bindHandlers(this.props, editor, initEvent);
    }
    renderInline() {
        const { tagName = 'div' } = this.props;
        return React.createElement(tagName, {
            ref: (elm) => (this.element = elm),
            id: this.id
        });
    }
    renderIframe() {
        return React.createElement("textarea", { ref: (elm) => (this.element = elm), style: { visibility: 'hidden' }, id: this.id });
    }
}
