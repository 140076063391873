var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import ConfigProvider from "antd/lib/config-provider";
import zhCN from 'antd/lib/locale-provider/zh_CN';
import zhTW from 'antd/lib/locale-provider/zh_TW';
import enUS from 'antd/lib/locale-provider/en_US';
import Router from './Router';
let Login = class Login extends React.Component {
    render() {
        const { location, lang, routesConfig, name = '' } = this.props;
        return (React.createElement("div", { className: `login ${name} lang-${lang}` },
            React.createElement(ConfigProvider, { locale: {
                    'zh-CN': zhCN,
                    'zh-TW': zhTW,
                    'en-US': enUS
                }[lang] },
                React.createElement(Router, { location: location, config: routesConfig }))));
    }
};
Login = __decorate([
    withRouter
], Login);
export default Login;
