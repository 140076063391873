import React, { createContext, useState } from 'react';
export const PayoutOrderContext = createContext(null);
const PayoutOrderProvider = ({ children }) => {
    const [payoutOrder, setPayoutOrder] = useState(new Map());
    const savePayoutOrder = (key, value) => {
        setPayoutOrder(prev => {
            const item = prev.get(key);
            prev.set(key, Object.assign(Object.assign({}, item), value));
            return prev;
        });
    };
    const removePayoutOrder = (key) => {
        setPayoutOrder(prev => {
            if (prev.has(key))
                prev.delete(key);
            return prev;
        });
    };
    return React.createElement(PayoutOrderContext.Provider, { value: { payoutOrder, savePayoutOrder, removePayoutOrder } }, children);
};
export default PayoutOrderProvider;
