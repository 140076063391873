import React, { useState } from 'react';
import Select from "antd/lib/select";
const { Option } = Select;
let timeout;
export default function SelectDropDown(props) {
    const [data, setData] = useState([]);
    const { placeholder, style, onChange, onSelect, serviceapi, debounce = 500, rowkey = 'id', getkey = 'id', showlabelkeys = [], settingformdata = [], form } = props;
    function showLabel(d, keys) {
        let text = '';
        if (keys.length > 0) {
            keys.map((m) => {
                text += `${d[m]} | `;
            });
            text = text.substring(0, text.lastIndexOf(' | '));
        }
        else {
            text = d['value'] || d['text'];
        }
        return text;
    }
    const options = data.length > 0 && data.map((d, i) => React.createElement(Option, { key: i, value: d['payment_token_id'] }, showLabel(d, showlabelkeys)));
    const fetch = (val, callback) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            const param = {
                uid: val
            };
            serviceapi(param)
                .then((res) => {
                callback(res || []);
            });
        };
        timeout = setTimeout(fake, debounce);
    };
    const onSearch = (val) => {
        if (val) {
            fetch(val, setData);
        }
    };
    const handSelect = (val, opt) => {
        const { key } = opt;
        const item = data[key];
        if (settingformdata.length > 0 && form) {
            const obj = {};
            settingformdata.map((d) => {
                obj[d] = '';
            });
            obj[rowkey] = item[getkey];
            form.setFieldsValue(obj);
        }
        onSelect && onSelect();
    };
    return (React.createElement(Select, Object.assign({}, props, { showSearch: true, placeholder: placeholder, style: style, onSearch: onSearch, onChange: onChange, onSelect: handSelect, filterOption: false, notFoundContent: null }), options));
}
