import * as React from 'react';
import ASelect from '@/components/AntdCustomComponent/ASelect';
import StaticView from './StaticView';
export default class SelectView extends React.Component {
    constructor() {
        super(...arguments);
        this.adaptHandler = (val) => {
            if (!val)
                return '-';
            const { mode, options } = this.props;
            const isMultiple = mode === 'multiple';
            const display = options && options.filter(({ value }) => isMultiple ? (val || []).includes(value) : val === value);
            return (display && display.length) && (isMultiple
                ? display.map(({ label }) => label).join(' / ')
                : display[0].label);
        };
    }
    render() {
        const { staticView, disabled, readOnly, value } = this.props;
        return (staticView && disabled || readOnly)
            ? React.createElement(StaticView, { adaptFn: () => this.adaptHandler(value), disabled: disabled })
            : React.createElement(ASelect, Object.assign({}, this.props));
    }
}
SelectView.fieldName = 'select';
SelectView.type = 'Select';
