import * as React from 'react';
import Col from "antd/lib/col";
import InputNumber from "antd/lib/input-number";
import Row from "antd/lib/row";
import Slider from "antd/lib/slider";
import * as intl from 'react-intl-universal';
import { CHANNEL_ROUNDING_PLACES_TO_TEXT, CHANNEL_ROUNDING_SAMPLE } from '@/utils/enum';
export default class SliderView extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = (e) => {
            this.setState({ roundingPlaces: e });
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        };
        this.state = {
            roundingPlaces: this.props.roundingPlaces,
            roundingMethod: this.props.roundingMethod,
            hideSliderInput: this.props.hideSliderInput,
            displayValue: '',
            minValue: this.props.minValue,
            maxValue: this.props.maxValue
        };
    }
    componentDidMount() {
        var _a;
        const method = this.state.roundingMethod;
        const places = (_a = this.state.roundingPlaces) !== null && _a !== void 0 ? _a : 0;
        const convertPlaces = CHANNEL_ROUNDING_PLACES_TO_TEXT[places];
        const finalConvertMethod = String(method).toUpperCase() + convertPlaces;
        this.setState({ displayValue: CHANNEL_ROUNDING_SAMPLE[finalConvertMethod] });
        this.props.onChange(places);
    }
    componentDidUpdate(prevProps, prevState) {
        const { roundingPlaces } = this.state;
        const { roundingMethod } = this.props;
        if (prevProps.roundingMethod !== roundingMethod || prevState.roundingPlaces !== roundingPlaces) {
            const convertPlaces = CHANNEL_ROUNDING_PLACES_TO_TEXT[roundingPlaces];
            const finalConvertMethod = String(roundingMethod).toUpperCase() + convertPlaces;
            this.setState({ displayValue: CHANNEL_ROUNDING_SAMPLE[finalConvertMethod] });
        }
    }
    render() {
        const { roundingPlaces, hideSliderInput, displayValue } = this.state;
        const { minValue, maxValue } = this.props;
        const marks = {
            [minValue]: minValue.toString(),
            [maxValue]: maxValue.toString(),
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(Row, null,
                React.createElement(Col, { span: 12 },
                    React.createElement(Slider, { min: minValue, max: maxValue, onChange: this.onChange, style: { marginLeft: '10px' }, marks: marks, defaultValue: 0, value: typeof roundingPlaces === 'number' ? roundingPlaces : 0 })),
                React.createElement(Col, { span: 4 }, !hideSliderInput &&
                    React.createElement(InputNumber, { min: minValue, max: maxValue, style: { margin: '0 16px' }, value: roundingPlaces, onChange: this.onChange, defaultValue: 0 }))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 16 },
                    React.createElement("div", { style: { marginLeft: '5px', marginTop: '10px' } },
                        intl.get('Payment.example'),
                        " ",
                        React.createElement("span", { style: { color: 'red', fontWeight: 'bold' } }, displayValue))))));
    }
}
SliderView.fieldName = 'slider';
SliderView.type = 'Slider';
