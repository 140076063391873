import lazy from '@/common/components/Lazy';
export default {
    'Setting.Shop': lazy(() => import('../Setting/Shop')),
    'Setting.Shop.Appearance': lazy(() => import('../Setting/Shop/Appearance')),
    'Setting.System.TimeZone': lazy(() => import('../Setting/System/TimeZone')),
    'Setting.Personal.Account': lazy(() => import('../Setting/Personal/Account')),
    'Setting.Personal.AccountManagement': lazy(() => import('../Setting/Personal/AccountManagement')),
    'Setting.Personal.AccountRole': lazy(() => import('../Setting/Personal/AccountRole')),
    'Message.Manage.All': lazy(() => import('../Message/Manage/All')),
    'Message.Manage.WorkOrder.List': lazy(() => import('../Message/Manage/WorkOrder')),
    'Message.Manage.WorkOrder.Detail': lazy(() => import('../Message/Manage/WorkOrder/Details')),
    'Message.Manage.WorkOrder.Create': lazy(() => import('../Message/Manage/WorkOrder/CreateWork')),
    'Message.Manage.System': lazy(() => import('../Message/Manage/System')),
    'Payment.PaymentSetting.Channel': lazy(() => import('../Payment/PaymentSetting/Channel')),
    'Payment.PaymentSetting.Channel.Edit': lazy(() => import('../Payment/PaymentSetting/Channel/Edit')),
    'Payment.PaymentSetting.VirtualChannel': lazy(() => import('../Payment/PaymentSetting/Channel/VirtualChannel')),
    'Payment.PaymentSetting.VirtualChannel.Edit': lazy(() => import('../Payment/PaymentSetting/Channel/VirtualChannel/Edit')),
    'Payment.PaymentSetting.VirtualChannel.Detail': lazy(() => import('../Payment/PaymentSetting/Channel/VirtualChannel/Detail')),
    'Payment.PaymentSetting.VirtualChannel.Detail.Edit': lazy(() => import('../Payment/PaymentSetting/Channel/VirtualChannel/DetailEdit')),
    'Payment.CommonSetting.ChannelBasis': lazy(() => import('../Payment/CommonSetting/ChannelBasis')),
    'Payment.CommonSetting.ApiWithdrawal': lazy(() => import('../Payment/CommonSetting/ApiWithdrawal')),
    'Payment.CommonSetting.ChannelKeys': lazy(() => import('../Payment/CommonSetting/ChannelKeys')),
    'Payment.CommonSetting.CallbackAddress': lazy(() => import('../Payment/CommonSetting/CallbackAddress')),
    'Order.Manage.Trade': lazy(() => import('../Order/Manage/Trade')),
    'Order.Manage.Trade.Detail': lazy(() => import('../Order/Manage/Trade/Details')),
    'Order.Test': lazy(() => import('../Order/Test')),
    'Order.Test.Create': lazy(() => import('../Order/Test/Create')),
    'Order.Test.Detail': lazy(() => import('../Order/Manage/Trade/Details')),
    'Order.Manage.Withdrawal': lazy(() => import('../Order/Manage/Withdrawal')),
    'Order.Manage.Withdrawal.Detail': lazy(() => import('../Order/Manage/Withdrawal/Details')),
    'Customer.Manage.List': lazy(() => import('../Customer/Manage/List')),
    'Customer.Manage.Group': lazy(() => import('../Customer/Manage/Group')),
    'Customer.Manage.Tag': lazy(() => import('../Customer/Manage/Tag')),
    'Payment.DepositSetting.Online': lazy(() => import('../Payment/DepositSetting/Online')),
    'Payment.DepositSetting.Online.Edit': lazy(() => import('../Payment/DepositSetting/Online/Edit')),
    'Payment.DepositSetting.Basic': lazy(() => import('../Payment/DepositSetting/Basic')),
    'Payment.CommonSetting.ExchangeRate': lazy(() => import('../Payment/CommonSetting/ExchangeRate')),
    'Payment.CommonSetting.ExchangeRate.Record': lazy(() => import('../Payment/CommonSetting/ExchangeRate/Record')),
    'Payment.StaticAccount': lazy(() => import('../Payment/StaticAccount')),
    'Payment.StaticAccount.Edit': lazy(() => import('../Payment/StaticAccount/Edit')),
    'Payment.WithdrawalSetting.PaymentAccount': lazy(() => import('../Payment/WithdrawalSetting/PaymentAccount')),
    'Payment.WithdrawalSetting.PaymentAccount.Edit': lazy(() => import('../Payment/WithdrawalSetting/PaymentAccount/Edit')),
    'Payout.Channel': lazy(() => import('../Payout/Channel')),
    'Payout.Channel.Edit': lazy(() => import('../Payout/Channel/Edit')),
    'Payout.Channel.Order.Create': lazy(() => import('../Payout/Channel/OrderCreate')),
    'Payout.Order': lazy(() => import('../Payout/Order')),
    'Payout.Order.Details': lazy(() => import('../Payout/Order/Details')),
    'Payout.Regular.ReceiverAccount': lazy(() => import('../Payout/ReceiverAccount')),
    'Payout.Regular.ReceiverAccount.Bank.Edit': lazy(() => import('../Payout/ReceiverAccount/EditBankAccount')),
    'Payout.Regular.ReceiverAccount.DigitalWallet.Edit': lazy(() => import('../Payout/ReceiverAccount/EditDigitalWallet')),
    'Payout.Webhook': lazy(() => import('../Payout/Webhook')),
    'DigitalCurrency.Channel': lazy(() => import('../DigitalCurrency/Channel')),
    'DigitalCurrency.Channel.Edit': lazy(() => import('../DigitalCurrency/Channel/Edit')),
    'DigitalCurrency.Wallet': lazy(() => import('../DigitalCurrency/Wallet')),
    'DigitalCurrency.OutFlowTransaction': lazy(() => import('../DigitalCurrency/OutFlowTransaction')),
    'DigitalCurrency.InFlowTransaction': lazy(() => import('../DigitalCurrency/InFlowTransaction')),
};
