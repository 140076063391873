import * as React from 'react';
import * as ReactDom from 'react-dom';
class ModalContainer extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            modalList: []
        };
        this.showDialog = (modal) => {
            this.state.modalList.push(modal);
            this.setState({
                modalList: this.state.modalList
            });
        };
        this.removeDialog = (id) => {
            const modalList = this.state.modalList.filter((modal) => modal.id !== id);
            this.setState({ modalList: modalList || [] });
        };
        this.removeAll = () => this.setState({
            modalList: []
        });
        this.eventListener = (type, param) => {
            if (type == 'closeModal') {
                this.removeDialog(param);
                return;
            }
        };
    }
    render() {
        return (React.createElement("div", { className: 'modal' }, this.state.modalList.map(({ id, option }) => {
            const { Dialog, config } = option;
            return React.createElement(Dialog, { key: id, id: id, config: config, onTrigger: this.eventListener });
        })));
    }
}
function getModalContainer(modalContainer) {
    const div = document.createElement('div');
    const Container = modalContainer;
    document.body.appendChild(div);
    return ReactDom.render(React.createElement(Container, null), div);
}
export default getModalContainer(ModalContainer);
