var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Checkbox from "antd/lib/checkbox";
import StaticView from './StaticView';
export default class CheckboxView extends React.Component {
    constructor() {
        super(...arguments);
        this.adaptHandler = (val) => {
            if (!val)
                return '-';
            const { options = [] } = this.props;
            const display = options && options.filter(({ value }) => (val || []).includes(value));
            return (display && display.length && display.map(({ label }) => label).join(' / ')) || '-';
        };
    }
    render() {
        const _a = this.props, { staticView, disabled, readOnly, options, value } = _a, props = __rest(_a, ["staticView", "disabled", "readOnly", "options", "value"]);
        return (staticView && disabled || readOnly)
            ? React.createElement(StaticView, { adaptFn: () => this.adaptHandler(value), disabled: disabled })
            : React.createElement(Checkbox.Group, Object.assign({}, props, { options: options }));
    }
}
CheckboxView.fieldName = 'checkbox';
CheckboxView.type = 'Checkbox';
