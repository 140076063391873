var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import Select from "antd/lib/select";
import i18n from '@/common/I18n';
import ASelect from '@/components/AntdCustomComponent/ASelect';
const { Option } = Select;
const languageChangeHandler = (language) => __awaiter(void 0, void 0, void 0, function* () {
    yield i18n.setLanguage(language);
});
export default function Selector() {
    const language = i18n.getDefault();
    const locales = i18n.getDisplay;
    return (React.createElement(ASelect, { className: "i18n-selector", onChange: languageChangeHandler, value: language }, locales.map(({ key, name }) => (React.createElement(Option, { key: key, value: key }, name)))));
}
