const getCookie = (name) => {
    const cookieName = name + '=';
    const cookie = document.cookie.split(';');
    for (let i = 0; i < cookie.length; i++) {
        const c = cookie[i].trim();
        if (c.indexOf(cookieName) == 0)
            return c.substring(cookieName.length, c.length);
    }
    return '';
};
const setCookie = (name, value, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + value + '; path=/; ' + expires;
};
export { getCookie, setCookie };
