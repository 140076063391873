import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Error } from '@/common/components';
const defaultRoute = (routes) => routes[0] || {};
const getAuthorities = (routes) => (routes || [])
    .reduce((prev, { key, children }) => (Object.assign(Object.assign({}, prev), { [key.match(/[A-Za-z0-9]+$/)[0]]: (children && children.length) ? getAuthorities(children) : true })), {});
const getHref = (href) => `/v2${href}`;
const declareRoutes = ({ routes, loaders, presets }, renderPageFooter) => (React.createElement(Switch, null,
    routes.filter(({ component, children }) => component || children)
        .map(({ key, href, exact, component, children }) => [
        component
            && (React.createElement(Route, { key: key, path: getHref(href), exact: typeof exact !== 'boolean' || exact, render: (props) => {
                    const Module = Error(loaders[component]);
                    return (React.createElement(Module, Object.assign({}, Object.assign({ renderPageFooter, authorities: getAuthorities(children) }, (presets || {})), props)));
                } })),
        children && children.filter((item) => item.href || item.component).length
            && (React.createElement(Route, { key: key, path: getHref(href), render: () => declareRoutes({
                    routes: children,
                    loaders,
                    presets
                }, renderPageFooter) }))
    ]),
    React.createElement(Route, { render: () => React.createElement(Redirect, { to: getHref((defaultRoute(routes)).href || '/') }) })));
export default class Router extends React.Component {
    constructor(props) {
        super(props);
        this.key = null;
        this.key = props.location.key;
    }
    shouldComponentUpdate(props) {
        const legacy = this.key;
        this.key = props.location.key;
        return this.key !== legacy;
    }
    render() {
        return declareRoutes(this.props.config, this.props.renderPageFooter);
    }
}
