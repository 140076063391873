import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';
import sagas from './sagas';
const sagaMiddleware = createSagaMiddleware();
let middleware = applyMiddleware(sagaMiddleware);
middleware = process.env.NODE_ENV === 'development' ? composeWithDevTools(middleware) : middleware;
export default createStore(reducers, middleware);
sagaMiddleware.run(sagas);
