import { is, List } from 'immutable';
export function isEqual(value, defaultValue, ignoreCasing = true) {
    return (typeof value === 'string' && typeof defaultValue === 'string' && ignoreCasing)
        ? value.toLowerCase() === defaultValue.toLowerCase()
        : is((value ? List(value) : value), (defaultValue ? List(defaultValue) : defaultValue));
}
export function segment(ary, columns) {
    const exclusiveArr = ary.filter(({ exclusiveLine }) => exclusiveLine);
    const lastIndex = Math.ceil(ary.length / columns) + exclusiveArr.length;
    const resArr = new Array(lastIndex);
    const len = ary.length;
    let n = 1;
    let j = 0;
    ary.forEach((item, index) => {
        resArr[j] = resArr[j] || [];
        resArr[j].push(item);
        n++;
        let nextIndex = index + 1;
        nextIndex = nextIndex === len ? index : nextIndex;
        if (item.exclusiveLine || ary[nextIndex].exclusiveLine || n > columns) {
            j++;
            n = 1;
        }
    });
    return resArr;
}
